import React from "react";
import PropTypes from "prop-types";
import { Typography, Modal, ConfirmationModal } from "@aiops/styleguide";

export type UsecaseConfirmationModalProps = {
  open: boolean;
  onClose: () => void;
  onCancel: () => void;
  onConfirm: () => void;
  title: string;
  message: string;
  cancelButtonText: string;
  confirmationButtonText: string;
};

const UsecaseConfirmationModal = ({
  open,
  onClose,
  onCancel,
  onConfirm,
  title,
  message,
  cancelButtonText,
  confirmationButtonText,
}: UsecaseConfirmationModalProps
) => {
  return (
    <Modal open={open} onClose={onClose}>
      <ConfirmationModal
        headlineText={title}
        cancelButtonText={cancelButtonText}
        confirmationButtonText={confirmationButtonText}
        onCancel={onCancel}
        onConfirm={onConfirm}
      >
        <Typography variant="paragraph1-light">
          {message}
        </Typography>
      </ConfirmationModal>
    </Modal>
  );
};

UsecaseConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  cancelButtonText: PropTypes.string.isRequired,
  confirmationButtonText: PropTypes.string.isRequired,
};

export default UsecaseConfirmationModal;
