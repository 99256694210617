import * as z from "zod";
import { DataConnectionFormType } from "../genaiSettingsHelper/genaiSettingsHelper";

export const GenericDataConnectionSchema = z.object({
  formValues: z.array(
    z.object({
      connection_type: z.object({
        label: z.string().optional(),
        value: z.string().optional(),
      }).optional().nullable(),
      connection_name: z.string().optional().nullable(),
      // API fields
      api_endpoint: z.string().optional().nullable(),
      api_method: z.object({
        label: z.string().optional().nullable(),
        value: z.string().optional().nullable(),
      }).optional().nullable(),
      // Database fields
      collection_name: z.string().optional().nullable(),
      database_name: z.string().optional().nullable(),
      data_limit: z.string().optional().nullable(),
      n_days_to_fetch_data: z.string().optional().nullable(),
      db_sort_field_name: z.string().optional().nullable(),
      connection_url_secret: z.string().optional().nullable(),
      // SQL fields
      database_type: z.object({
        label: z.string().optional().nullable(),
        value: z.string().optional().nullable(),
      }).optional().nullable(),
      database_host: z.string().optional().nullable(),
      db_user_secret_name: z.string().optional().nullable(),
      db_password_secret_name: z.string().optional().nullable(),
      // FILE STORE fields
      file_store: z.object({
        label: z.string().optional().nullable(),
        value: z.string().optional().nullable(),
      }).optional().nullable(),
      bucket_name: z.string().optional().nullable(),
      object_key: z.string().optional().nullable(),
      tool_name: z.string().optional(),
      // SEMANTIC SEARCH fields
      semantic_store: z.object({
        label: z.string().optional().nullable(),
        value: z.string().optional().nullable(),
      }).optional().nullable(),
      semantic_store_index: z.string().optional().nullable(),
    })
  ).optional(),
})




export const GENERIC_DATA_CONNECTION_FIELDS = {
  API: [
    {
      control_label: "api_endpoint",
      required: true,
      label: "API Endpoint*",
      type: "text",
      secret: false,
    },
    {
      control_label: "api_method",
      required: true,
      label: "API Method*",
      type: "dropdown",
      secret: false,
      data_key: "API"
    },
  ],
  DOCUMENTDB: [
    {
      control_label: "collection_name",
      required: true,
      label: "Collection Name*",
      type: "text",
      secret: false,
    },
    {
      control_label: "database_name",
      required: true,
      label: "Database Name*",
      type: "text",
      secret: false,
    },
    {
      control_label: "data_limit",
      required: false,
      label: "Data Limit",
      type: "text",
      secret: false,
    },
    {
      control_label: "n_days_to_fetch_data",
      required: false,
      label: "Number of Days to Fetch Data",
      type: "text",
      secret: false,
    },
    {
      control_label: "db_sort_field_name",
      required: false,
      label: "Database Sort Field Name",
      type: "text",
      secret: false,
    },
    {
      control_label: "connection_url_secret",
      required: true,
      label: "Connection URL Secret*",
      type: "text",
      secret: true,
    },
  ],
  SQL: [
    {
      control_label: "database_type",
      required: true,
      label: "Database Type*",
      type: "dropdown",
      secret: false,
      data_key: "SQL"
    },
    {
      control_label: "database_host",
      required: true,
      label: "Database Host*",
      type: "text",
      secret: false,
    },
    {
      control_label: "database_name",
      required: true,
      label: "Database Name*",
      type: "text",
      secret: false,
    },
    {
      control_label: "data_limit",
      required: false,
      label: "Data Limit",
      type: "text",
      secret: false,
    },
    {
      control_label: "db_user_secret_name",
      required: true,
      label: "DB User Secret Name*",
      type: "text",
      secret: true,
    },
    {
      control_label: "db_password_secret_name",
      required: true,
      label: "DB Password Secret Name*",
      type: "text",
      secret: true,
    },
  ],
  SEMANTIC_SEARCH: [
    {
      control_label: "semantic_store",
      required: true,
      label: "Semantic Store*",
      type: "dropdown",
      secret: false,
      data_key: "SEMANTIC_SEARCH",
    },
    {
      control_label: "semantic_store_index",
      required: true,
      label: "Semantic Store Index*",
      type: "text",
      secret: false,
    }
  ],
  FILE: [
    {
      control_label: "file_store",
      required: true,
      label: "File Store*",
      type: "dropdown",
      secret: false,
      data_key: "FILE"
    },
    {
      control_label: "bucket_name",
      required: true,
      label: "Bucket Name*",
      type: "text",
      secret: false,
    },
    {
      control_label: "object_key",
      required: true,
      label: "Object Key (File Name)*",
      type: "text",
      secret: false,
    }
  ]
}

export const TEST_ENABLED_CONNECTION_TYPES = [
  "SQL",
  "DOCUMENTDB",
  "FILE",
];

export function formatConnectionsForForm(tabData: DataConnectionFormType[], isSemanticStore: boolean) {
  const form = [];
  tabData?.filter((c) => {
    if (isSemanticStore) {
      return c.connection_type === "SEMANTIC_SEARCH";
    } else {
      return c;
    }
  })?.forEach((connection) => {
    form.push({
      connection_type: {
        label: isSemanticStore ? "SEMANTIC_SEARCH" : connection.connection_type ?? "",
        value: isSemanticStore ? "SEMANTIC_SEARCH" : connection.connection_type ?? "",
      },
      connection_name: connection.connection_name,
      api_endpoint: connection.api_endpoint,
      api_method: {
        label: connection.api_method,
        value: connection.api_method,
      },
      collection_name: connection.collection_name,
      database_name: connection.database_name,
      data_limit: connection?.data_limit ? String(connection.data_limit) : null,
      n_days_to_fetch_data: connection?.n_days_to_fetch_data ? String(connection.n_days_to_fetch_data) : null,
      db_sort_field_name: connection.db_sort_field_name,
      connection_url_secret: connection.connection_url_secret_name,
      database_type: {
        label: connection.database_type,
        value: connection.database_type,
      },
      database_host: connection.database_host,
      db_user_secret_name: connection.database_user_secret_name,
      db_password_secret_name: connection.database_password_secret_name,
      tool_name: connection.tool_name,
      file_store: {
        label: connection.file_store,
        value: connection.file_store,
      },
      bucket_name: connection.bucket_name,
      object_key: connection.object_key,
      semantic_store: {
        label: connection.semantic_store,
        value: connection.semantic_store,
      },
      semantic_store_index: connection.semantic_store_index,
      tool_type: {
        label: connection.tool_type,
        value: connection.tool_type,
      },
    })
  });
  if (form.length === 0) {
    form.push({
      connection_type: {
        label: "",
        value: "",
      },
      tool_name: ""
    });
  }
  return {
    formValues: form,
  };
}

export const defaultGenericDataConnectionFormValues = {
  formValues: [{
    connection_type: {
      label: "",
      value: "",
    },
    tool_name: ""
  }]
}

export function getKeyFromConnectionType(connectionType: any) {
  return connectionType?.label;
}

type ErrorObjectType = {
  hasError: boolean;
  form: DataConnectionFormType[];
}

export function getValueFromRecord(record: any) {
  if (typeof record === "object") {
    return record?.label ?? null;
  } else if (typeof record === "string") {
    return record !== "" ? record : null;
  } else {
    return null;
  }
}

const REQUIRED_FIELD_ERR = {
  type: "required",
  message: "This field is required",
}

export function genericDataConnectionFormErrorHandler(form: Record<string, Array<any>>, setError: Function): ErrorObjectType {
  const response: ErrorObjectType = {
    hasError: false,
    form: [],
  }
  const formValues = form.formValues;
  formValues.forEach((connection, index) => {
    const connectionType = getValueFromRecord(connection.connection_type);
    if (connectionType) {

      GENERIC_DATA_CONNECTION_FIELDS[connectionType].forEach((field) => {
        if (field.required && !getValueFromRecord(connection[field.control_label])) {
          setError(`formValues[${index}].${field.control_label}`, REQUIRED_FIELD_ERR);
          response.hasError = true;
        }
      });
    } else {
      setError(`formValues[${index}].connection_type`, REQUIRED_FIELD_ERR);
      response.hasError = true;
    }
    if (!connection.tool_name) {
      setError(`formValues[${index}].tool_name`, REQUIRED_FIELD_ERR);
      response.hasError = true;
      return response
    }
  });

  response.form = formValues.map((connection) => {
    return {
      tool_name: getValueFromRecord(connection.tool_name),
      tool_description: "",
      connection_name: getValueFromRecord(connection.tool_name),
      connection_type: getValueFromRecord(connection.connection_type),
      api_endpoint: getValueFromRecord(connection.api_endpoint),
      api_method: getValueFromRecord(connection.api_method),
      collection_name: getValueFromRecord(connection.collection_name),
      database_name: getValueFromRecord(connection.database_name),
      data_limit: connection.data_limit ? Number(getValueFromRecord(connection.data_limit)) : null,
      n_days_to_fetch_data: connection.n_days_to_fetch_data ? Number(getValueFromRecord(connection.n_days_to_fetch_data)) : null,
      db_sort_field_name: getValueFromRecord(connection.db_sort_field_name),
      connection_url_secret_name: getValueFromRecord(connection.connection_url_secret),
      database_type: getValueFromRecord(connection.database_type),
      database_host: getValueFromRecord(connection.database_host),
      database_password_secret_name: getValueFromRecord(connection.db_password_secret_name),
      database_user_secret_name: getValueFromRecord(connection.db_user_secret_name),
      file_store: getValueFromRecord(connection.file_store),
      bucket_name: getValueFromRecord(connection.bucket_name),
      object_key: getValueFromRecord(connection.object_key),
      semantic_store: getValueFromRecord(connection.semantic_store),
      semantic_search_index: getValueFromRecord(connection.semantic_store_index),
    }
  });

  return response;
}
