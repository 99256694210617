import { useGenAISettingsContext } from "@/context";
import UsecaseFormWithCustomFooter, { DropDownType, FormType } from "../UsecaseFormWithCustomFooter";
import { useEffect, useState } from "react";
import { FormAutocomplete, FormTextfield, useToast, IconButton } from "@aiops/styleguide";
import { zodResolver } from "@hookform/resolvers/zod";
import { DEPLOYMENT_PLATFORM_FIELDS, LLM_FORM_FIELDS, formatLlmConfigurationFormForPost, getDropdownValues, llmConfigurationErrorHandler, llmConfigurationForm } from "../UsecaseFormWithCustomFooter/utils";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import addIcon from "../../assets/icons/add.svg";
import removeIcon from "../../assets/icons/remove.svg";
import "./LlmConfigurationForm.scss";

export const LlmConfigurationFormContent = ({
  control,
  disabled,
  llmModelProviderOptions,
  llmModelNameOptions,
  embeddingPlatformOptions,
  embeddingModelOptions,
  showSemanticStoreFields,
}: {
  control: any,
  disabled: boolean,
  showSemanticStoreFields: boolean,
  llmModelProviderOptions: DropDownType[],
  llmModelNameOptions: DropDownType[],
  embeddingPlatformOptions: DropDownType[],
  embeddingModelOptions: DropDownType[],
}) => {
  const DROPDOWN_DATA_BY_KEY = {
    llm_model_provider: llmModelProviderOptions,
    llm_model_name: llmModelNameOptions,
    embedding: embeddingPlatformOptions,
    embedding_model: embeddingModelOptions,
  };
  function isFieldDisplayed(showSemanticStoreFields: boolean, field: any) {
    return (showSemanticStoreFields && field?.isSemanticSearchOnly) || !field.isSemanticSearchOnly;
  }
  

  return (
    <div className="grouped-llm-config">
      {LLM_FORM_FIELDS.map((field) => {
        if (field.type === "text" && isFieldDisplayed(showSemanticStoreFields, field)) {
          return (
            <FormTextfield
              key={field.control_label}
              control={control}
              controlLabel={field.control_label}
              label={field.label}
              placeholder=""
              width={350}
              textfieldProps={{
                disabled: disabled,
                placeholder: field.placeholder,
                margin: "dense",
              }}
            />
          );
        } else if (field.type === "autocomplete" && isFieldDisplayed(showSemanticStoreFields, field)) {
          return (
            <FormAutocomplete
              key={field.control_label}
              control={control}
              label=""
              displayName={field.label}
              controlLabel={field.control_label}
              data={DROPDOWN_DATA_BY_KEY[field.control_label] || []}
              width={350}
              multiple={false}
              disabled={disabled}
              disableCloseOnSelect={false}
              textfieldProps={{
                disabled: disabled,
                placeholder: field.placeholder,
                margin: "dense",
              }}
            />
          );
        }
      })}
    </div>
  );
}

const DeploymentPlatformFormContent = ({
  control,
  disabled,
}: {
  control: any,
  disabled: boolean,
}) => {
  return (
    <div className="flex col deployment-specific-fields">
      {DEPLOYMENT_PLATFORM_FIELDS.map((field) => {
        return (
          <FormTextfield
            key={field.control_label}
            control={control}
            controlLabel={field.control_label}
            label={field.label}
            placeholder=""
            width={300}
            textfieldProps={{
              disabled: disabled,
              placeholder: field.placeholder,
              margin: "dense",
            }}
          />
        );
      })}
    </div>
  );
};

export const DefaultQueryFormContent = ({
  fields,
  register,
  onAppend,
  onRemove,
  control,
  disabled,
}: {
  fields: Array<any>,
  register: any,
  onAppend: Function,
  onRemove: Function,
  control: any,
  disabled: boolean,
}) => {
  const reachedMaxLength = fields.length === 6;

  const handleRemove = (index: number) => {    
    onRemove(index);
    if(index === 0) {
      onAppend({
        title: "",
        description: "",
      });
    }
  }
  
  return (
    <div className="flex row usecase-queries">
      {fields.map((field, index) => (
        <div key={field.id} className="suggested-query">
          <FormTextfield
            control={control}
            controlLabel={`default_usecase_sample_query.${index}.title`}
            placeholder=""
            width={350}
            label="Default Use Case Sample Query"
            textfieldProps={{
              disabled: disabled,
              placeholder: "Title*"
            }}
          />
          <FormTextfield
            control={control}
            controlLabel={`default_usecase_sample_query.${index}.description`}
            placeholder=""
            width={350}
            textfieldProps={{
              minRows: 2,
              maxRows: 2.5,
              placeholder: "Description*",
              multiline: true,
              disabled: disabled,
            }}
            />
            <div className="flex row add-remove-action">
              {<IconButton 
                  onClick={() => {
                    if (!disabled) {
                      handleRemove(index);
                    }
                  }}
                  data-testid="remove-btn-testid"
                >
                <img
                  src={removeIcon}
                  alt="remove"
                  className={"add-remove-btn".concat(disabled ? " disabled" : "")}
                />
              </IconButton>}
              {!reachedMaxLength && index === fields.length - 1 &&
                <IconButton 
                  onClick={() => {
                    if (!disabled) {
                      onAppend({
                        title: "",
                        description: "",
                      });
                    }
                  }}
                  data-testid="add-btn-testid"
                >
                  <img
                    src={addIcon}
                    alt="add"
                    className={"add-remove-btn".concat(disabled ? " disabled" : "")}
                  />
                </IconButton>
              }
            </div>
        </div>
      ))}
    </div>
  );
}

const LlmConfigurationForm = ({ llmConfigTabData: currentTabData, editable = false, setEditable, readOnlyDisabled, onSave, isSemanticStore }: FormType) => {
  const { setIsUsecaseDetailsUnsaved, isUsecaseDetailsFormValid, setIsUsecaseDetailsFormValid } = useGenAISettingsContext();

  const [llmModelTypeOptions, setLlmModelTypeOptions] = useState<DropDownType[]>([]);
  const [llmModelNameOptions, setLlmModelNameOptions] = useState<DropDownType[]>([]);
  const [embeddingPlatformOptions, setLlmEmbeddingPlatformOptions] = useState<DropDownType[]>([]);
  const [embeddingModelOptions, setEmbeddingModelOptions] = useState<DropDownType[]>([]);
  const [llmDeploymentPlatformOptions, setLlmDeploymentPlatformOptions] = useState<DropDownType[]>([]);
  const { setCurrentToast } = useToast();

  const { control, handleSubmit, formState: { dirtyFields, isDirty, isValid }, setValue, getValues, reset, setError, watch, register } = useForm({
    resolver: zodResolver(llmConfigurationForm),
    values: {
      semantic_store: currentTabData?.semantic_store ? {
        label: currentTabData.semantic_store,
        value: currentTabData.semantic_store,
      } : null,
      semantic_store_index: currentTabData?.semantic_store_index || "",
      llm_model_provider: currentTabData?.llm_model_provider ? {
        label: currentTabData.llm_model_provider,
        value: currentTabData.llm_model_provider,
      } : null,
      llm_model_name: currentTabData?.llm_model_name ? {
        label: currentTabData.llm_model_name,
        value: currentTabData.llm_model_name,
      } : null,
      embedding: currentTabData?.embedding ? {
        label: currentTabData.embedding,
        value: currentTabData.embedding,
      } : null,
      embedding_model: currentTabData?.embedding_model ? {
        label: currentTabData.embedding_model,
        value: currentTabData.embedding_model,
      } : null,
      llm_deployment_platform: currentTabData?.llm_deployment_platform ? {
        label: currentTabData.llm_deployment_platform,
        value: currentTabData.llm_deployment_platform,
      } : null,
      llm_azure_deployment_name: currentTabData?.llm_azure_deployment_name || "",
      embedding_model_azure_deployment: currentTabData?.embedding_model_azure_deployment || "",
      llm_azure_api_version: currentTabData?.llm_azure_api_version || "",
      llm_azure_model_version: currentTabData?.llm_azure_model_version || "",
      default_usecase_info_message: currentTabData?.default_usecase_info_msg || "",
      default_usecase_sample_query: currentTabData?.default_usecase_sample_query?.length === 0 ? [{
        title: "",
        description: "",
      }] : currentTabData?.default_usecase_sample_query,
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "default_usecase_sample_query",
  });
  watch("llm_model_provider");
  watch("embedding");
  watch("llm_deployment_platform");  

  useEffect(() => {
    getDropdownValues("llm_text_model_type", setLlmModelTypeOptions, setCurrentToast);
  }, []);

  useEffect(() => {    
    const { llm_model_provider } = getValues();
    if (llm_model_provider?.value) {
      getDropdownValues("llm_model_name", setLlmModelNameOptions, setCurrentToast, llm_model_provider.value);
      getDropdownValues("llm_deployment_platform", setLlmDeploymentPlatformOptions, setCurrentToast, llm_model_provider.value)
      getDropdownValues("embedding_platform", setLlmEmbeddingPlatformOptions, setCurrentToast);
    }
    
  }, [getValues("llm_model_provider.value")]);

  useEffect(() => {
    const { embedding } = getValues();
    if (embedding?.value) {
      getDropdownValues("embedding_model", setEmbeddingModelOptions, setCurrentToast, embedding.value);
    }
  }, [getValues("embedding.value")]);

  useEffect(() => {
    setIsUsecaseDetailsUnsaved(isDirty);
  }, [isDirty]);

  useEffect(() => {
    if (isDirty) {
      setValue("llm_model_name", null);
      setValue('llm_deployment_platform', null);
      setValue('llm_azure_deployment_name', "");
      setValue('embedding_model_azure_deployment', "");
      setValue('llm_azure_api_version', "");
      setValue('llm_azure_model_version', "");
    }
  }, [getValues('llm_model_provider.value')]);

  useEffect(() => {
    if (isDirty){
      setValue("embedding_model", null);
    }
  }, [getValues('embedding.value')]);

  useEffect(() => {
    setIsUsecaseDetailsFormValid({...isUsecaseDetailsFormValid, llmConfigurationForm: isValid});
  }, [isValid]);

  const onSubmit: SubmitHandler<any> = (form: any) => {
    if (!llmConfigurationErrorHandler(form, isSemanticStore, setError)) {
      const payload = formatLlmConfigurationFormForPost(form);
      onSave(payload, "llm_configuration_details");
    }
  }
  
  return (
    <UsecaseFormWithCustomFooter
      editable={editable}
      onSubmit={handleSubmit(onSubmit, onSubmit)}
      saveDisabled={!editable || Object.keys(dirtyFields).length === 0}
      setEditable={setEditable}
      readOnlyDisabled={readOnlyDisabled}
      reset={reset}
    >
      <div className="llm-form-wrapper">
        <div className="main-content">
          <LlmConfigurationFormContent
            control={control}
            disabled={!editable}
            llmModelProviderOptions={llmModelTypeOptions}
            llmModelNameOptions={llmModelNameOptions}
            embeddingPlatformOptions={embeddingPlatformOptions}
            embeddingModelOptions={embeddingModelOptions}
            showSemanticStoreFields={isSemanticStore}
          />
        <div>
          <DefaultQueryFormContent
            fields={fields}
            register={register}
            control={control}
            onAppend={append}
            onRemove={remove}
            disabled={!editable}
          />
        </div>
        <div className="default-message">
          <FormTextfield
            control={control}
            controlLabel="default_usecase_info_message"
            label="Default Use Case Info Message"
            placeholder=""
            width={700}
            textfieldProps={{
              multiline: true,
              minRows: 4,
              disabled: !editable,
              placeholder: "Default message",
              margin: "dense"
            }}
          />
        </div>
        </div>
        <div className="deployment-platform-data">
          <div className="llm-platform">
            <FormAutocomplete
              control={control}
              label=""
              displayName={"LLM Deployment Platform"}
              controlLabel="llm_deployment_platform"
              data={llmDeploymentPlatformOptions}
              width={350}
              multiple={false}
              disabled={!editable}
              disableCloseOnSelect={false}
              textfieldProps={{
                disabled: !editable,
                placeholder: "Select",
                margin: "dense",
              }}
            />
          </div>
          {getValues("llm_deployment_platform")?.value === "AZURE" && <DeploymentPlatformFormContent
            control={control}
            disabled={!editable}
          />}
        </div>
      </div>
    </UsecaseFormWithCustomFooter>
  );
};

export default LlmConfigurationForm;
