import { useEffect, useRef, useState } from "react";
import "./MicrosolutionRow.scss";
import MicrosolutionCard from "../MicrosolutionCard";
import { CircularProgress, Typography, IconButton } from "@aiops/styleguide";
import { MicrosolutionType, getMicrosolutions } from "../genaiSettingsHelper/genaiSettingsHelper";
import { formatMicrosolutionsAlphabetically } from "../GenAISettingsHomePage";
import scrollIcon from "../../assets/icons/scrollIcon.svg";
import scrollDisabledIcon from "../../assets/icons/scrollDisabled.svg";

const MicrosolutionsRow = () => {
  const [
    existingMicrosolutions,
    setExistingMicrosolutions
  ] = useState<MicrosolutionType[]>(null);
  const [error, setError] = useState<string>(null);
  const [cardsLoading, setCardsLoading] = useState<boolean>(false);
  const [leftArrowDisable, setLeftArrowDisable] = useState<boolean>(true);
  const [rightArrowDisable, setRightArrowDisable] = useState<boolean>(false);
  const elementRef = useRef(null);

  async function triggerGetMicrosolutions() {
    setCardsLoading(true);
    await getMicrosolutions().then((res) => {
      setExistingMicrosolutions(formatMicrosolutionsAlphabetically(res))
    }).catch(() => {
      setError("Error fetching existing microsolutions");
    }).finally(() => {
      setCardsLoading(false);
    });
  }

  const handleHorizantalScroll = (element: React.MutableRefObject<any>, step: number) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.current.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= 250) {
        clearInterval(slideTimer);
      }      
      setLeftArrowDisable(element.current.scrollLeft === 0);
      setRightArrowDisable(element.current.offsetWidth + element.current.scrollLeft >= element.current.scrollWidth);
    }, 20);
  };

  useEffect(() => {
    triggerGetMicrosolutions();
  }, []);

  return (
    <div className="studio-content">
      <Typography variant="heading2-titles">
        GenAI Studio
      </Typography>
      {cardsLoading ? <div className="flex col"><CircularProgress data-testid="circular-progress" /></div> :
        <div>
          {error ? (
            <div className="has-error">
              <Typography variant="body2">
                {error}
              </Typography>
            </div>
          ) : (
            <div className="row">
              <div className={`scroll-icon-wrapper left ${leftArrowDisable ? "disabled" : ""}`}>
                <IconButton 
                  onClick={() => handleHorizantalScroll(elementRef, -10)}
                  disabled={leftArrowDisable}
                  className="left-arrow"
                  disableRipple
                >
                  <img
                    src={leftArrowDisable ? scrollDisabledIcon : scrollIcon}
                    alt="scroll-left"
                  />
                </IconButton>
              </div>
              <div className="microsolution-cards flex row" ref={elementRef}>
                {existingMicrosolutions?.map((microsolution) => {
                  return (
                    <MicrosolutionCard
                      key={microsolution.microsolution_id}
                      microsolution_id={microsolution.microsolution_id}
                      microsolution_name={microsolution.microsolution_name}
                      usecases={microsolution.usecases}
                    />)
                })}
              </div>
              <div className={`scroll-icon-wrapper right ${rightArrowDisable ? "disabled" : ""}`}>
                <IconButton 
                  onClick={() => handleHorizantalScroll(elementRef, 10)}   
                  disabled={rightArrowDisable}
                  className="right-arrow"
                  disableRipple                 
                >
                  <img
                    src={rightArrowDisable ? scrollDisabledIcon : scrollIcon}
                    alt="scroll-right"
                  />
                </IconButton>
              </div>
            </div>
          )}
        </div>}
    </div>
  );
}

export default MicrosolutionsRow;
