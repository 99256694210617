import { GENERIC_DATA_CONNECTION_FIELDS } from "../utils";

export function formatUsecaseType(usecaseType: string = "") {
  return usecaseType?.replace("_", ' ')
}

export function formatGenericTestPayload(form: Record<string, any>): Record<string, any> {
  const payload: Record<string, string | number> = {
    database_type: form?.database_type?.value || null,
    database_host: form?.database_host,
    database_name: form?.database_name,
    database_password_secretname: form?.db_password_secret_name,
    database_username_secretname: form?.db_user_secret_name,
    connection_url_secret_name: form?.connection_url_secret,
    collection_name: form?.collection_name,
    file_store: form?.file_store?.value || null,
    bucket_name: form?.bucket_name,
    object_key: form?.object_key,
  }
  return payload;
}

export function hasConnectionErrors(
  form: Record<string, any>,
  index: number, setErrors: Function
): boolean {
  let hasError = false;
  GENERIC_DATA_CONNECTION_FIELDS[form?.connection_type?.value].forEach((field) => {
    if (field.required && !form[field.control_label]) {
      hasError = true;
      setErrors(`formValues[${index}].${field.control_label}`, {
        type: "required",
        message: "This field is required",
      });
    }
  })

  return hasError;
}
