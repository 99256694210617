import * as React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import GenAISettingsHomePage from "../GenAISettingsHomePage";
import UsecaseDetails from "../UsecaseDetails";
import { navigateToUrl } from "single-spa";
import { getConfig } from "@aiops/root-config";
import GenAISettingsContextProvider from "../../context/GenAISettingsContext";

/**
 * Type for props of GenAISettings component.
 * 
 * @param basePath
 * The entire base path at which this component lives, including the settings
 * app's base path (ie: "/settings/gen-ai").
 * 
 */
export type GenAISettingsProps = {
  basePath: string,
};

/**
 * Root component for the GenAI settings page. Is imported and rendered by the
 * settings app.
 */
const GenAISettings = ({ basePath }: GenAISettingsProps) => {
  const navToSettings = () => {
    navigateToUrl(`/${getConfig().appList[0]?.settingsPath || "settings"}`);
  }

  // TODO: create real 404, probably in styleguide
  const temp404 = () => (
    <div className="col">
      404 not found
    </div>
  );

  return (
    <GenAISettingsContextProvider>
      <Router basename={basePath}>
        <Routes>
          <Route
            path={'/'}
            element={<GenAISettingsHomePage navToSettings={navToSettings} />}
          />
          <Route
            path={"usecase/:microsolution?/:usecase?"}
            element={<UsecaseDetails />}
          />
          <Route
            path={'*'}
            element={temp404()}
          />
        </Routes>
      </Router>
    </GenAISettingsContextProvider>
  );
};

export default GenAISettings;
