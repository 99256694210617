import {
  useState,
  useContext,
  createContext,
  useEffect,
  useMemo,
} from "react";
import { getConfig } from "@aiops/root-config";
import { getNewChatId } from "../chatbotApiHelper";
import { ChatbotMessageProps } from '../ChatbotMessage';
import { GenAIContextType } from "../chatbotApiHelper/apiHelper";

/**
 * Type for the ChatbotContext
 * 
 * @param appId
 * The appId of the app for which this is a context.
 * 
 * @param appName
 * The name of the app for which this is a context.
 * 
 * @param sessionId
 * The sessionId of the current chat.
 * 
 * @param expanded
 * The current expansion state of the chat.
 * 
 * @param setExpanded
 * Function to set the expanded state of the chat.
 * 
 * @param suggestedQueries
 * The list of suggested queries for the current context.
 * Each object is a key value pair { title: string, description: string }
 * 
 * @param setSuggestedQueries
 * Function to set the suggested queries for the current context.
 * 
 * @param awaitingResponse
 * Whether the chatbot is currently awaiting a response.
 * 
 * @param setAwaitingResponse
 * Function to set the awaiting response state.
 * 
 * @param contexts
 * The list of contexts for this app.
 * 
 * @param currentContext
 * The currently-selected context.
 * 
 * @param setCurrentContext
 * Function to set the current context.
 * 
 * @param chatHistory
 * List of messages in the chat history, where chatHistory[0] is the oldest
 * message and chatHistory[chatHistory.length - 1] is the newest message.
 * 
 * @param addToChatHistory
 * Function to add a message to the chat history.
 * 
 */
type ChatbotContextType = {
  appId: string,
  appName: string,
  sessionId: string,
  expanded: boolean,
  setExpanded: (expanded: boolean) => void,
  suggestedQueries: Record<string, string>[],
  setSuggestedQueries: (suggestedQueries: Record<string, string>[]) => void,
  awaitingResponse: boolean,
  setAwaitingResponse: (awaitingResponse: boolean) => void,
  contexts: GenAIContextType[],
  currentContext: GenAIContextType,
  setCurrentContext: (context: GenAIContextType) => void,
  chatHistory: ChatbotMessageProps[],
  addToChatHistory: (message: any) => void,
  clearChat: () => void,
};

/**
 * The actual context object.
 */
const ChatbotContextCtxtObj = createContext<ChatbotContextType>({
  appId: '',
  appName: '',
  sessionId: '',
  expanded: false,
  setExpanded: () => { },
  suggestedQueries: [],
  setSuggestedQueries: () => { },
  awaitingResponse: false,
  setAwaitingResponse: () => { },
  contexts: [],
  currentContext: null,
  setCurrentContext: () => { },
  chatHistory: [],
  addToChatHistory: () => { },
  clearChat: () => { },
});

// This is exported separately for easier testing.
export const appNameFromId = (id: string) => {
  return getConfig()?.appList?.find((app) => app?.appId === id)?.appName || "This Microsolution";
}

/**
 * Hook to access the ChatbotContext context object.
 */
export const useChatbotContext = (): ChatbotContextType => {
  return useContext(ChatbotContextCtxtObj);
};

/**
 * Renders the ChatbotContext's Provider.
 */
const ChatbotContextProvider = ({ appId, contexts, children }) => {
  const [appName] = useState<string>(appNameFromId(appId));
  const [expanded, setExpanded] = useState<boolean>(false);
  const [suggestedQueries, setSuggestedQueries] = useState<Record<string, string>[]>([]);
  const [sessionId, setSessionId] = useState<string>('');
  const [currentContext, actuallySetCurrentContext] = useState<GenAIContextType | null>(null);
  const [chatHistory, setChatHistory] = useState<any[]>([]);
  const [awaitingResponse, setAwaitingResponse] = useState<boolean>(false);

  /**
   * Set the current context. Throws an error if the context is not in the list
   * of contexts for this app.
   * 
   * @param context 
   * The context to set as the current context.
   */
  const setCurrentContext = (context: GenAIContextType) => {
    // allow contexts to be set to null
    if (context !== null && !contexts.includes(context)) {
      throw new Error(`Context ${context} is not in the list of contexts for this app.`);
    }
    actuallySetCurrentContext(context);
  }

  /**
   * Add a message to the chat history.
   * 
   * @param message 
   * The message to add to the chat history.
   */
  const addToChatHistory = (message: any) => {
    setChatHistory((prevState) => [...prevState, message]);
  }

  /**
   * Clear the chat history.
   */
  const clearChat = () => {
    setChatHistory([]);
    setSuggestedQueries([]);
    actuallySetCurrentContext(null);
    setSessionId(getNewChatId(appId));
  }

  // Whenever appId changes, get a new sessionId.
  useEffect(() => {
    setSessionId(getNewChatId(appId));
  }, [appId]);

  // Memoize the context value.
  const value = useMemo(() => ({
    appId,
    appName,
    sessionId,
    expanded,
    setExpanded,
    suggestedQueries,
    setSuggestedQueries,
    awaitingResponse,
    setAwaitingResponse,
    contexts,
    currentContext,
    setCurrentContext,
    chatHistory,
    addToChatHistory,
    clearChat,
  }), [
    appId,
    appName,
    sessionId,
    expanded,
    setExpanded,
    suggestedQueries,
    setSuggestedQueries,
    awaitingResponse,
    setAwaitingResponse,
    contexts,
    currentContext,
    setCurrentContext,
    chatHistory,
    addToChatHistory,
    clearChat,
  ]);

  return (
    <ChatbotContextCtxtObj.Provider value={value}>
      {children}
    </ChatbotContextCtxtObj.Provider>
  );
};

export default ChatbotContextProvider;
