import "./ChatbotContextPicker.scss";
import { useChatbotContext } from "../ChatbotContext";
import {
    Menu,
    MenuItem,
    Typography,
} from "@aiops/styleguide";
import { chatbotContextChangeObject, defaultContextMessageObject } from "../ChatbotMessage";
import { GenAIContextType, getContextMessageAndQueries } from "../chatbotApiHelper/apiHelper";

type ChatbotContextPickerMenuProps = {
    onClose: () => void,
    anchorElement: any,
    width: number,
}

const ChatbotContextPickerMenu = ({ onClose, anchorElement, width }: ChatbotContextPickerMenuProps) => {

    // Style object for MUI Menu
    const menuListProps = {
        style: {
            width: `${width}px`,
            backgroundColor: 'var(--fig)',
            color: 'var(--white)',
            display: 'flex',
            flexDirection: 'column' as const, // as const required for TypeScript
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            padding: '0px',
        }
    }

    const {
        appId,
        contexts,
        setCurrentContext,
        addToChatHistory,
        setSuggestedQueries,
    } = useChatbotContext();

    const getDefaultMessage = async (context: string) => {
        const { usecaseMessage, usecaseQueries } = await getContextMessageAndQueries(appId, context);        
        addToChatHistory(defaultContextMessageObject(usecaseMessage));
        setSuggestedQueries(usecaseQueries);
      }

    const setContextAndAddToHistory = (context: GenAIContextType) => {
        setCurrentContext(context);
        addToChatHistory(chatbotContextChangeObject(context.name));
    }

    const menuItem = (context: GenAIContextType, idx: number) => {
        const border = '1px solid var(--sad-fig)';
        return (
            <MenuItem
                key={context.name}
                onClick={() => {
                    setContextAndAddToHistory(context);
                    getDefaultMessage(context.name);
                    onClose();
                }}
                className="aiops-chatbot-context-picker-menu-item"
                // This styling gets overwritten by a MUI class if it's done as
                // scss with a classname, which is why it's inline here.
                sx={{
                    padding: '0px 4px',
                    margin: '0px',
                    borderTop: `${idx === 0 ? border : ''}`,
                    borderBottom: border,
                    width: '100%',
                }}
            >
                <Typography
                    variant="paragraph1"
                    align="center"
                >
                    {context.name}
                </Typography>
            </MenuItem>
        )
    }

    return (
        <Menu
            MenuListProps={menuListProps}
            anchorEl={anchorElement}
            open={Boolean(anchorElement)}
            onClose={onClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
            {contexts.map((c, i) => (
                menuItem(c, i)
            ))}
        </Menu>
    )

}

export default ChatbotContextPickerMenu;
