import { Copy, Retry, Button } from "@aiops/styleguide";
import { useChatbotContext } from "../ChatbotContext";
import { useEffect, useState } from "react";
import { DISCLAIMER } from "../../constants";
import "./ChatbotMessageFooter.scss";

interface ChatbotMessageFooterProps {
  sender: "USER" | "BOT" | "CONTEXT" | "SYSTEM";
  onRetry: () => void;
  message: string;
  index?: number
};

const ChatbotMessageFooter = (props: ChatbotMessageFooterProps) => {
  const [copyClicked, setCopyClicked] = useState<Boolean>(false);
  const { sender, message, onRetry, index: currentIndex } = props;
  const [hasChatStarted, setHasChatStarted] = useState<Boolean>(false);
  const [hasUserInitiatedChat, setHasUserInitiatedChat] = useState<Boolean>(false);
  const [isLastBotMessage, setIsLastBotMessage] = useState<Boolean>(false);
  const { chatHistory } = useChatbotContext();

  const onCopy = () => {
    navigator.clipboard.writeText(message.concat("\n--\n").concat(DISCLAIMER));
    setCopyClicked(true);
    setTimeout(() => {
      setCopyClicked(false);
    }, 5000);
  };

  const handleRetry = (message: string) => {
    onRetry();
  };

  const getHasUserInitiatedChat = () => {    
    const chatTillNow = chatHistory.slice(0, currentIndex).filter((item) => {
      return item.sender === "USER";
    });
    return chatTillNow.length > 0;
  };

  const lastBotMessageIndex = chatHistory.length - chatHistory
  .slice()
  .reverse()
  .findIndex((item) => {
    return item.sender === "BOT";
  }) - 1;
  const lastUserMessage = chatHistory.filter((item) => {
    return item.sender === "USER";
  }).splice(-1).pop();

  useEffect(() => {
    if (chatHistory) {
      const chatStarted = Boolean(chatHistory.filter((item) => {
        return item.sender === "USER";
      }).length);
      setHasChatStarted(chatStarted);
      setIsLastBotMessage(currentIndex === lastBotMessageIndex);
      setHasUserInitiatedChat(getHasUserInitiatedChat());
    }
  }, [chatHistory]);  

  return (
    <div
      className="row aiops-chatbot-message-footer"
      style={{
        display: (sender !== "BOT" || !hasUserInitiatedChat) && "none"
      }}
    >
      <div className="left">
        {hasChatStarted && (
          <>
            <Button onClick={onCopy} className="chatbot-message-footer-button">
              <Copy data-testid="mockedCopy" copyClicked={copyClicked}/>
            </Button>
            {isLastBotMessage && (
              <Button onClick={() => handleRetry(lastUserMessage.message)} className="chatbot-message-footer-button">
                <Retry data-testid="mockedRetry"/>
              </Button>
            )}
          </>
        )}
      </div>
      <div className="right">
        {/* right end content goes here */}
      </div>
    </div>
  );
};

export default ChatbotMessageFooter;
