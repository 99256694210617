import "./ChatbotHeader.scss";
import ChatbotContextPicker from "../ChatbotContextPicker";
import { useChatbotContext } from "../ChatbotContext";
import {
  Typography,
  RefreshIcon,
  CloseIcon,
  OpenInFullIcon,
  CloseFullscreenIcon,
} from "@aiops/styleguide";

/**
 * Type for props of ChatbotHeader component.
 */
export type ChatbotHeaderProps = {
  isExpanded?: boolean;
  setIsExpanded: null | ((isExpanded: boolean) => void);
  closeWindow: null | (() => void);
};

/**
 * Renders a header for the chatbot window, including the hello ai title, app
 * name, row of buttons, and context picker.
 */
const ChatbotHeader = ({ isExpanded = false, setIsExpanded, closeWindow }) => {
  const { appName, clearChat, awaitingResponse } = useChatbotContext();

  return (
    <div className="aiops-chatbot-header-outer-wrap col">
      <div className="aiops-chatbot-header-inner-wrap">
        <div className="aiops-chatbot-header-title-col">
          <Typography
            variant="heading6-bold"
            className="heading"
          >
            Digital Assistant
          </Typography>
          <Typography
            variant="subheading1"
            className="subheading"
          >
            {appName}
          </Typography>
        </div>
        <div className="row">
          {!awaitingResponse && <button
            data-testid="refresh-chat-btn"
            onClick={clearChat}
            className="aiops-chatbot-button"
          >
            <RefreshIcon />
          </button>}
          {
            setIsExpanded && <button
              data-testid="toggle-expanded-btn"
              onClick={() => setIsExpanded(!isExpanded)}
              className="aiops-chatbot-button"
            >
              {
                isExpanded
                  ? <CloseFullscreenIcon />
                  : <OpenInFullIcon />
              }
            </button>
          }
          {
            closeWindow && <button
              data-testid="close-window-btn"
              onClick={closeWindow}
              className="aiops-chatbot-button close-button"
            >
              <CloseIcon />
            </button>
          }
        </div>
      </div >
      <ChatbotContextPicker />
    </div >
  );
};

export default ChatbotHeader;
