import React from 'react';
import "./MicrosolutionCard.scss";
import { Typography } from '@aiops/styleguide';
import { MicrosolutionType } from '../genaiSettingsHelper/genaiSettingsHelper';
import { getConfig } from '@aiops/root-config';
import { AddUsecaseButton } from '../GenAISettingsHomePage';
import { utils } from '@aiops/styleguide';
import { getUsecaseCount } from '../genaiSettingsHelper/utils';

const { PROCESS_AREA_ICONS } = utils;

const usecaseCountRenderer = (usecaseCount: number, hasUsecases: boolean) => {
  return (
    <div className={`usecase-count row ${hasUsecases ? "happy" : "sad"}`}>
      <Typography variant="caption1c" className="counter flex">{usecaseCount || 0}</Typography>
      <Typography className="usecase-text" variant="caption-italic">{(usecaseCount <= 1) ? "Use Case" : "Use Cases"}</Typography>
    </div>
  );
}

const MicrosolutionCard = ({ microsolution_id, microsolution_name, usecases }: MicrosolutionType) => {
  const useCaseCount = getUsecaseCount(usecases);
  const hasUsecases = useCaseCount > 0;
  const iconEnum = getConfig().appList.find(a => a.appId === microsolution_id)?.iconEnum;
  
  const icon = PROCESS_AREA_ICONS[iconEnum || "GENERIC_ICON"];
  
  return (
    <div className={`microsolution-card-wrapper flex col ${hasUsecases && "shiny"}`}>
      <div className="microsolution-icon">
        <img src={icon?.toString()} alt="microsolution-icon" className={`${hasUsecases ? "" : "inactive"}`} />
      </div>
      <div className="microsolution-name">
        <Typography variant="subheding1-light">{microsolution_name}</Typography>
      </div>
      {usecaseCountRenderer(useCaseCount, hasUsecases)}
      <AddUsecaseButton microsolution={microsolution_id} />
    </div>
  );
}

export default MicrosolutionCard;
