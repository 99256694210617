import propTypes from "prop-types";
import { getNavigateURL } from '../../genaiSettingsHelper/utils';
import { LinkButton } from "@aiops/styleguide";
import { useNavigate } from "react-router-dom";
import { useGenAISettingsContext } from "@/context/GenAISettingsContext";
import "./UsecaseNameLink.scss";

const UsecaseNameLink = ({ usecaseName = "", microsolutionId = "" }) => {
    const navigate = useNavigate();
    const { setIsNewUsecase } = useGenAISettingsContext();
    return (
        <div className="usecase-link-button">
            <LinkButton onClick={() => {
                setIsNewUsecase(false);
                navigate(
                    getNavigateURL({ microsolution: microsolutionId, usecase: usecaseName })
            )}}>
                {usecaseName}
            </LinkButton>
        </div>
    );
}

UsecaseNameLink.propTypes = {
    usecaseName: propTypes.string.isRequired,
    microsolutionId: propTypes.string.isRequired,
};

export default UsecaseNameLink;