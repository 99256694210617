import React, { useEffect, useState } from "react";
import "./GenericDataConnectionForm.scss";
import UsecaseFormWithCustomFooter, { DropDownType, FormType } from "../UsecaseFormWithCustomFooter";
import ConnectionField from "./ConnectionField";
import { Button, Typography, useToast } from "@aiops/styleguide";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { GenericDataConnectionSchema, formatConnectionsForForm, genericDataConnectionFormErrorHandler } from "./utils";
import { getDropdownValues } from "../UsecaseFormWithCustomFooter/utils";
import { useGenAISettingsContext } from "@/context";

const GenericDataConnectionForm = ({
  dataConnectionTabData,
  editable,
  setEditable,
  onSave,
  readOnlyDisabled,
  isSemanticStore,
}: FormType) => {
  const { setCurrentToast } = useToast();
  const { setIsUsecaseDetailsUnsaved, isUsecaseDetailsFormValid, setIsUsecaseDetailsFormValid } = useGenAISettingsContext();
  const { control, handleSubmit, formState: { dirtyFields, isDirty, isValid, errors }, getValues, setError, watch, clearErrors, reset } = useForm({
    resolver: zodResolver(GenericDataConnectionSchema),
    values: formatConnectionsForForm(dataConnectionTabData, isSemanticStore),
  });
  const [connectionTypes, setConnectionTypes] = useState<DropDownType[]>([]);
  const [apiMethodTypes, setApiMethodTypes] = useState<DropDownType[]>([]);
  const [databaseTypes, setDatabaseTypes] = useState<DropDownType[]>([]);
  const [fileStoreTypes, setFileStoreTypes] = useState<DropDownType[]>([]);
  const [semanticStoreOptions, setSemanticStoreOptions] = useState<DropDownType[]>([]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "formValues",
  });

  useEffect(() => {
    setIsUsecaseDetailsUnsaved(isDirty);
  }, [isDirty]);

  useEffect(() => {
    setIsUsecaseDetailsFormValid({ ...isUsecaseDetailsFormValid, dataConnectionForm: isValid });
  }, [isValid]);

  useEffect(() => {
    getDropdownValues("connection_type", setConnectionTypes, setCurrentToast);
    getDropdownValues("API_METHOD", setApiMethodTypes, setCurrentToast);
    getDropdownValues("DATABASE_TYPE", setDatabaseTypes, setCurrentToast);
    getDropdownValues("file_store", setFileStoreTypes, setCurrentToast);
    getDropdownValues("semantic_store", setSemanticStoreOptions, setCurrentToast);
  }, []);

  const onSubmit: SubmitHandler<any> = (form: any) => {
    const { form: finalForm, hasError } = genericDataConnectionFormErrorHandler(form, setError);
    if (!hasError && finalForm.length !== 0) {
      onSave(finalForm, "data_connection_details");
    }
  };

  return (
    <UsecaseFormWithCustomFooter
      editable={editable}
      // passing onSubmit in both onValid and onInvalid since custom error handler
      // is build in to onSubmit
      onSubmit={handleSubmit(onSubmit, onSubmit)}
      saveDisabled={!editable || Object.keys(dirtyFields).length === 0}
      setEditable={setEditable}
      readOnlyDisabled={readOnlyDisabled}
      reset={reset}
    >
      <div className="generic-form-wrapper">
        {fields.map((connection, index) => (
          <ConnectionField
            key={connection?.id}
            tabData={connection}
            control={control}
            index={index}
            editable={editable}
            isSemanticStore={isSemanticStore}
            connectionTypes={connectionTypes}
            apiMethodTypes={apiMethodTypes}
            databaseTypes={databaseTypes}
            fileStoreTypes={fileStoreTypes}
            semanticStoreOptions={semanticStoreOptions}
            remove={remove}
            setToast={setCurrentToast}
            disableDelete={fields.length === 1}
            getValues={getValues}
            watch={watch}
            errors={errors}
            setErrors={setError}
            clearErrors={clearErrors}
          />
        ))}
        {!isSemanticStore && (
          <div className="add-connection">
            <Button
              variant="contained"
              disabled={!editable}
              onClick={() => append({
                connection_type: {
                  value: "",
                  label: "",
                },
                tool_name: "",
              })}
              className="add-connection-button"
            >
              <Typography variant="paragraph1">
                <div className="plus-icon">+</div>
                <div>Connection Type</div>
              </Typography>
            </Button>
          </div>
        )}
      </div>
    </UsecaseFormWithCustomFooter>
  );
};

export default GenericDataConnectionForm;
