import { useState, useContext, createContext, useMemo } from "react";
/**
 * Type for the GenAISettingsContext
 *
 * @param isUsecaseDetailsUnsaved
 * Boolean variable to track the unsaved changes in the use case details form
 *
 * @param setIsUsecaseDetailsUnsaved
 * Function to set if there are any unsaved changes in the use case details form
 *
 * @param isUsecaseConfirmationModalOpen
 * Object structure {isOpen: boolean, triggerPoint: string} where:
 * isOpen is a boolean to track if the unsaved changes modal is open
 * triggerPoint is a string to track the point where the modal was triggered (form-tab-switch, back-btn-click, cancel-btn-click)
 *
 * @param setIsUsecaseConfirmationModalOpen
 * Function to set if the unsaved changes modal is open and the point where it was triggered
 *
 * @param usecaseConfirmationModalButtonClicked
 * String variable to track the button clicked in the unsaved changes modal
 * Takes values "yes"/"no"/"" as UsecaseConfirmationModalButtonType
 * 
 * @param setUsecaseConfirmationModalButtonClicked
 * Function to set the button clicked in the unsaved changes modal
 * 
 * @param isUsecaseDetailsFormValid
 * Object structure {overviewDetailsForm: boolean, dataConnectionForm: boolean, llmConfigurationForm: boolean} where:
 * overviewDetailsForm is a boolean to track if the overview details form is valid
 * dataConnectionForm is a boolean to track if the data connection form is valid
 * llmConfigurationForm is a boolean to track if the llm configuration form is valid
 * 
 * @param setIsUsecaseDetailsFormValid
 * Function to set if the overview details form, data connection form, and llm configuration form are valid
 * 
 * @param isNewUsecase
 * Boolean variable to track if the use case is new or existing
 */
type GenAISettingsContextType = {
  isUsecaseDetailsUnsaved: boolean;
  setIsUsecaseDetailsUnsaved: (arg0: boolean) => void;
  isUsecaseConfirmationModalOpen: UsecaseConfirmationModalObjectType;
  setIsUsecaseConfirmationModalOpen: (
    arg0: UsecaseConfirmationModalObjectType
  ) => void;
  usecaseConfirmationModalButtonClicked: UsecaseConfirmationModalButtonType;
  setUsecaseConfirmationModalButtonClicked: (arg0: UsecaseConfirmationModalButtonType) => void;
  isUsecaseDetailsFormValid: ValidFormType;
  setIsUsecaseDetailsFormValid: (arg0: ValidFormType) => void;
  isNewUsecase: boolean;
  setIsNewUsecase: (arg0: boolean) => void;
};

/**
 * Type for the modal trigger point
 * @param "form-tab-switch"
 * String value to track the point where the modal was triggered when switching tabs in the form
 * @param "back-btn-click"
 * String value to track the point where the modal was triggered when clicking the back button to navigate back to settings page
 * @param "cancel-btn-click"
 * String value to track the point where the modal was triggered when clicking the cancel button in the form
 **/
export type ModalTriggerPointType =
  | "form-tab-switch"
  | "back-btn-click"
  | "cancel-btn-click"
  | "";

/**
 * Type for the unsaved changes modal object
 * @param isOpen
 * Boolean variable to track if the unsaved changes modal is open
 * @param triggerPoint
 * String variable to track the point where the modal was triggered
 */
export type UsecaseConfirmationModalObjectType = {
  isOpen: boolean;
  triggerPoint: ModalTriggerPointType;
};

export type ValidFormType = {
  overviewDetailsForm: boolean;
  dataConnectionForm: boolean;
  llmConfigurationForm: boolean;
};

export type UsecaseConfirmationModalButtonType = "yes" | "no" | "";

/**
 * The actual context object.
 */
const GenAISettingsContextObj = createContext<GenAISettingsContextType>({
  isUsecaseDetailsUnsaved: false,
  setIsUsecaseDetailsUnsaved: () => {},
  isUsecaseConfirmationModalOpen: { isOpen: false, triggerPoint: "" },
  setIsUsecaseConfirmationModalOpen: () => {},
  usecaseConfirmationModalButtonClicked: "" as UsecaseConfirmationModalButtonType,
  setUsecaseConfirmationModalButtonClicked: (arg0: UsecaseConfirmationModalButtonType) => {},
  isUsecaseDetailsFormValid: {} as ValidFormType,
  setIsUsecaseDetailsFormValid: (arg0: ValidFormType) => {},
  isNewUsecase: false,
  setIsNewUsecase: () => {},
});

/**
 * Hook to access the GenAISettingsContext context object.
 */
export const useGenAISettingsContext = (): GenAISettingsContextType => {
  return useContext(GenAISettingsContextObj);
};

/**
 * Renders the GenAISettingsContext's Provider.
 */
const GenAISettingsContextProvider = ({ children }) => {
  const [isUsecaseDetailsUnsaved, setIsUsecaseDetailsUnsaved] =
    useState<boolean>(false);
  const [isUsecaseConfirmationModalOpen, setIsUsecaseConfirmationModalOpen] =
    useState<UsecaseConfirmationModalObjectType>({
      isOpen: false,
      triggerPoint: "",
    });
  const [usecaseConfirmationModalButtonClicked, setUsecaseConfirmationModalButtonClicked] = useState<UsecaseConfirmationModalButtonType>("");
  const [isUsecaseDetailsFormValid, setIsUsecaseDetailsFormValid] =
    useState<ValidFormType>({
      overviewDetailsForm: false,
      dataConnectionForm: false,
      llmConfigurationForm: false,
    });
  const [isNewUsecase, setIsNewUsecase] = useState<boolean>(false);

  // Memoize the context value.
  const value = useMemo(
    () => ({
      isUsecaseDetailsUnsaved,
      setIsUsecaseDetailsUnsaved,
      isUsecaseConfirmationModalOpen,
      setIsUsecaseConfirmationModalOpen,
      usecaseConfirmationModalButtonClicked,
      setUsecaseConfirmationModalButtonClicked,
      isUsecaseDetailsFormValid,
      setIsUsecaseDetailsFormValid,
      isNewUsecase,
      setIsNewUsecase,
    }),
    [
      isUsecaseDetailsUnsaved,
      setIsUsecaseDetailsUnsaved,
      isUsecaseConfirmationModalOpen,
      setIsUsecaseConfirmationModalOpen,
      usecaseConfirmationModalButtonClicked,
      setUsecaseConfirmationModalButtonClicked,
      isUsecaseDetailsFormValid,
      setIsUsecaseDetailsFormValid,
      isNewUsecase,
      setIsNewUsecase,
    ]
  );

  return (
    <GenAISettingsContextObj.Provider value={value}>
      {children}
    </GenAISettingsContextObj.Provider>
  );
};

export default GenAISettingsContextProvider;
