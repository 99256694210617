import { useState, useEffect } from "react";
import "./ChatbotWrap.scss";
import { getChatbotContexts } from "../chatbotApiHelper";
import ChatbotContextProvider from "../ChatbotContext";
import { GenAIContextType } from "../chatbotApiHelper/apiHelper";

/**
 * Type for props of ChatbotWrap component.
 */
export type ChatbotWrapProps = {
  appId: string;
  children?: React.ReactNode | React.ReactNodeArray;
};

/**
 * Component that wraps/opens/closes the chatbot window.
 */
const ChatbotWrap = ({ appId, children }: ChatbotWrapProps) => {
  const [contexts, setContexts] = useState<GenAIContextType[]>([]);

  const getAndSetContext = async (appId: string) => {
    if (!appId) {
      setContexts([]);
      return;
    }
    try {
      const res = await getChatbotContexts(appId);
      setContexts(res)
    } catch (e) {
      console.error("Error getting chatbot contexts", e);
      setContexts([]);
    }
  }

  useEffect(() => {
    getAndSetContext(appId);
  }, [appId]);

  return (
    <div className="aiops-chatbot-wrap">
      <ChatbotContextProvider appId={appId} contexts={contexts}>
        {children}
      </ChatbotContextProvider>
    </div>
  );
};

export default ChatbotWrap;
