import { useGenAISettingsContext } from "@/context";
import { useEffect, useState } from "react";
import UsecaseFormWithCustomFooter, { DropDownType, FormType } from "../UsecaseFormWithCustomFooter";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { getDropdownValues, overviewForm } from "../UsecaseFormWithCustomFooter/utils";
import { UsecaseOverviewType, getMicrosolutions } from "../genaiSettingsHelper/genaiSettingsHelper";
import { FormAutocomplete, FormTextfield, useToast, FormSwitch } from "@aiops/styleguide";
import { returnBooleanValue } from "@/components/genaiSettingsHelper/utils";
import "./UseCaseOverviewForm.scss";

const UsecaseOverviewContent = ({ control, data, disabled, disableChangeContext = false }: { control: any, data: any, disabled: boolean, disableChangeContext: boolean }) => {
  const [usecaseTypes, setUsecaseTypes] = useState<DropDownType[]>([]);
  const { setCurrentToast } = useToast();

  useEffect(() => {
    getDropdownValues("usecase_type", setUsecaseTypes, setCurrentToast);
  }, [])

  return (
    <div>
      <div className="row basic-details">
        <FormAutocomplete
          label=""
          displayName="Microsolution ID*"
          control={control}
          disabled={disabled || disableChangeContext}
          multiple={false}
          controlLabel="microsolution_id"
          data={data}
          width={350}
          disableCloseOnSelect={false}
          textfieldProps={{
            disabled: disabled || disableChangeContext,
            placeholder: "Select",
            margin: "dense",
          }}
          data-testid="microsolution_id"
        />
        <FormTextfield
          control={control}
          controlLabel="usecase_name"
          label="Use Case Name*"
          placeholder=""
          textfieldProps={{
            disabled: disabled || disableChangeContext,
            placeholder: "Enter use case name",
            margin: "dense"
          }}
          data-testid="usecase_name"
        />
        <FormAutocomplete
          label=""
          displayName="Use Case Type*"
          control={control}
          multiple={false}
          controlLabel='usecase_type'
          data={usecaseTypes}
          width={350}
          disabled={disabled}
          disableCloseOnSelect={false}
          textfieldProps={{
            disabled: disabled,
            placeholder: "Select",
            margin: "dense",
          }}
          data-testid="usecase_type"
        />
      </div>
      <div className="row details-prompt">
        <div className="column">
            <FormTextfield
              control={control}
              controlLabel="usecase_description"
              label="Use Case Description*"
              placeholder=""
              textfieldProps={{
                multiline: true,
                minRows: 4,
                disabled: disabled,
                placeholder: "Enter details",
                margin: "dense"
              }}
              data-testid="usecase_description"
            />
            <div className="streaming-switch">
              <div className={disabled ? "disabled-streaming-switch" : null}>
                <FormSwitch
                  controlLabel="streaming_enabled"
                  control={control}
                  label="Live Text Responses"
                  switchProps={{
                    disabled: disabled,
                  }}
                  data-testid="streaming_enabled_switch"
                />
              </div>
            </div>
          </div>
        <FormTextfield
          control={control}
          controlLabel="usecase_prompt"
          placeholder=""
          label="Use Case Prompt"
          textfieldProps={{
            multiline: true,
            minRows: 8,
            maxRows: 12,
            disabled: disabled,
            placeholder: "Enter details",
            margin: "dense"
          }}
          data-testid="usecase_prompt"
        />
      </div>
    </div>
  );
}

const UseCaseOverviewForm = ({ usecaseTabData: tabData, editable = false, onSave, disableChangeContext, setEditable, readOnlyDisabled }: FormType) => {
  const { setIsUsecaseDetailsUnsaved, isUsecaseDetailsFormValid, setIsUsecaseDetailsFormValid } = useGenAISettingsContext();
  const [availableMicrosolutions, setAvailableMicrosolutions] = useState<Array<Record<string, string>>>([]);
  const { handleSubmit, control, reset, formState: { dirtyFields, isDirty, isValid } } = useForm({
    resolver: zodResolver(overviewForm),
    defaultValues: {
      microsolution_id: tabData?.microsolution_id ? {
        label: tabData?.microsolution_id,
        value: tabData?.microsolution_id,
      } : null,
      usecase_name: tabData?.usecase_name || "",
      usecase_type: tabData?.usecase_type ? {
        label: tabData?.usecase_type,
        value: tabData?.usecase_type,
      } : null,
      usecase_description: tabData?.usecase_description || "",
      usecase_prompt: tabData?.usecase_prompt || "",
      streaming_enabled: returnBooleanValue(tabData?.streaming_enabled),
    }
  });

  async function getMicrosolutionsData() {
    await getMicrosolutions().then((res) => {
      if (typeof res !== "string") {
        const microsolutions = res.map((microsolution) => {
          return {
            label: microsolution.microsolution_id,
            value: microsolution.microsolution_id,
          }
        });
        setAvailableMicrosolutions(microsolutions);
      }
    });
  }

  useEffect(() => {
    if (!tabData?.usecase_name) {
      getMicrosolutionsData();
    }
  }, [])

  useEffect(() => {
    setIsUsecaseDetailsUnsaved(isDirty);
  }, [isDirty]);

  useEffect(() => {
    setIsUsecaseDetailsFormValid({...isUsecaseDetailsFormValid, overviewDetailsForm: isValid});
    }, [isValid]);

  const onSubmit: SubmitHandler<any> = (form: any) => {
    const data: UsecaseOverviewType = {
      microsolution_id: form.microsolution_id.value,
      usecase_name: form.usecase_name.trim(),
      usecase_type: form.usecase_type.value,
      usecase_description: form.usecase_description,
      usecase_prompt: form.usecase_prompt,
      streaming_enabled: form.streaming_enabled,
    }
    onSave(data, "overview_details")
  }


  return (
    <UsecaseFormWithCustomFooter
      onSubmit={handleSubmit(onSubmit)}
      saveDisabled={!editable || Object.keys(dirtyFields).length === 0}
      editable={editable}
      setEditable={setEditable}
      readOnlyDisabled={readOnlyDisabled}
      reset={reset}
    >
      <UsecaseOverviewContent control={control} data={availableMicrosolutions || []} disabled={!editable} disableChangeContext={disableChangeContext} />
    </UsecaseFormWithCustomFooter>
  );
};

export default UseCaseOverviewForm;
