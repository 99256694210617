import React from "react";
import "./GenAISettingsHomePage.scss";
import { useNavigate } from "react-router-dom";
import { Button, Typography, StickyAppFooter } from "@aiops/styleguide";
import { MicrosolutionType } from "../genaiSettingsHelper/genaiSettingsHelper";
import MicrosolutionTable from "../MicrosolutionTable";
import MicrosolutionsRow from "../MicrosolutionRow";
import { getNavigateURL } from "../genaiSettingsHelper/utils";
import { useGenAISettingsContext } from "@/context/GenAISettingsContext";

/**
 * Type for props of GenAISettingsHomePage component.
 */
export type GenAISettingsHomePageProps = {
  navToSettings: Function,
};

export const AddUsecaseButton = ({
  microsolution = "",
  usecase = "",
}: {
  microsolution?: string;
  usecase?: string;
}) => {
  const navigate = useNavigate();
  const { setIsNewUsecase } = useGenAISettingsContext();
  
  const addUsecaseBtnHandler = () => {
    setIsNewUsecase(true);
    navigate(
      getNavigateURL({
        microsolution,
        usecase,
      })
    );
  }

  return (
    <div className="usecase-btn-wrapper">
      <Button variant="outlined" className="add-usecase-btn" onClick={addUsecaseBtnHandler}>
        <span className="plus">+</span>
        <Typography variant="body2">Use Case</Typography>
      </Button>
    </div>
  );
}

/**
 *
 */

export const formatMicrosolutionsAlphabetically = (microsolutions: MicrosolutionType[]) => {
  // sort microsolutions alphabetically
  const sortedData = [...microsolutions].sort(function (a, b) {
    let x = a.microsolution_name.toLowerCase();
    let y = b.microsolution_name.toLowerCase();

    if (x > y) {
      return 1;
    }
    if (x < y) {
      return -1;
    }
    return 0;
    // place microsolutions with 0 usecases in the end
  }).sort((a, b) => {
    if (a.usecases === null && b.usecases !== null) {
      return 1;
    } else if (a.usecases !== null && b.usecases === null) {
      return -1;
    } else {
      return 0;
    }
  })

  return sortedData;
};


const GenAISettingsHomePage = ({ navToSettings }: GenAISettingsHomePageProps) => {

  const handleBackToSettings = () => {
    navToSettings();
  }

  return (
    <div className="aiops-studio-wrapper flex">
      <MicrosolutionsRow />
      <MicrosolutionTable />
      <StickyAppFooter>
          <Button variant="outlined" className="footer-back-btn" onClick={handleBackToSettings}>Back</Button>
          <AddUsecaseButton />
      </StickyAppFooter>
    </div>
  );
};

export default GenAISettingsHomePage;
