import { AppWrapper, AuthProvider } from "@aiops/styleguide";
import ChatbotWrap from "./components/ChatbotWrap";
import ChatbotHeader from "./components/ChatbotHeader";
import ChatbotChat from "./components/ChatbotChat";

export default function Root(props) {
  return (
    <AppWrapper>
      <AuthProvider>
        <ChatbotWrap appId={props?.app?.appId}>
          <div
            className="col"
            style={{
              height: "calc(100vh - var(--header-height))",
            }}
          >
            <ChatbotHeader
              setIsExpanded={null}
              closeWindow={null}
            />
            <ChatbotChat />
          </div>
        </ChatbotWrap>
      </AuthProvider>
    </AppWrapper>
  );
}
