import * as React from "react";
import "./ChatbotMessage.scss";
import { Typography } from "@aiops/styleguide";
import Markdown from 'react-markdown';
import remarkGfm from "remark-gfm";
import ChatbotMessageFooter from "../ChatbotMessageFooter";
import { GenAIContextType } from "../chatbotApiHelper/apiHelper";

/**
 * Returns a button styled as a context pill. If the onClick function provided
 * is null, the button will be white and not clickable. If the onClick function
 * provided is truthy, the button will be green and clickable.
 * 
 * @param context 
 * The text of the button.
 * 
 * @param onClick 
 * Null if the button is not clickable, otherwise a function to run on click.
 * 
 * @returns 
 * <button> component.
 * 
 */
export const contextPill = (context: string, onClick: null | (() => void)) => (
  <button
    key={context}
    onClick={onClick}
    className={`aiops-chatbot-context-pill ${onClick ? 'pill-clickable' : 'pill-unclickable'}`}
  >
    <Typography
      variant="caption1"
    >
      {context}
    </Typography>
  </button>
)

/**
 * Returns a ChatbotMessage object that can be added to the chat history to
 * tell the user that the context has changed.
 * 
 * @param context 
 * The new context.
 * 
 * @returns
 * Object that can be added to the chat history. 
 */
export const chatbotContextChangeObject = (context: string): ChatbotMessageProps => ({
  sender: "CONTEXT",
  message: "Help me understand...",
  children: <div>
    {contextPill(context, null)}
  </div>
})

/**
 * Returns a ChatbotMessage object that can be added to the chat history
 * to display the context description.
 * @param message 
 * The context description message that needs to be displayed.
 * @returns 
 * Object that can be added to the chat history
 */
export const defaultContextMessageObject = (message: string): ChatbotMessageProps => ({
  sender: "BOT",
  message: message,
  children: null,
})

/**
 * Returns a ChatbotMessage object with all available contexts as clickable
 * pills, for when a user's query is invalid. It assumes the llm response (ie
 * the message in the object) includes a prompt for the user to select a
 * context.
 * 
 * @param message 
 * The text that will be displayed. 
 * 
 * @param currentContext 
 * The currently selected context.
 * 
 * @param contexts 
 * List of all available contexts, including the currently selected context.
 * 
 * @param onSetContext 
 * Function that runs when a context pill is clicked.
 * 
 */
export const getInvalidResponseObject = (
  message: string,
  currentContext: GenAIContextType,
  contexts: GenAIContextType[],
  onSetContext: (s: GenAIContextType, m: ChatbotMessageProps) => void,
): ChatbotMessageProps => {
  const children = (
    <div className="row">
      {
        contexts.map((c) => contextPill(c.name, c === currentContext ? null : () => onSetContext(c, chatbotContextChangeObject(c.name))))
      }
    </div>
  )

  return {
    sender: "BOT",
    message,
    children,
  }
}

/**
 * Type for props of ChatbotMessage component.
*/
export type ChatbotMessageProps = {
  sender: 'USER' | 'BOT' | 'SYSTEM' | 'CONTEXT';
  message?: string;
  children?: React.ReactNode | React.ReactNode[];
  includesProductCards?: boolean;
  openInSameTab?: boolean;
  onRetry?: () => void;
  index?: number;
};

const CustomMarkdown = ({ message, openInSameTab } : { message: string, openInSameTab?: boolean } ) => {
  function LinkRenderer(props: any) {
    const linkProps = openInSameTab ? {} : {
      target: "_blank",
      rel: "noreferrer",
    }
    return (
      <a href={props.href} {...linkProps}>
        {props.children}
      </a>
    );
  }
  return (
    <Markdown
      remarkPlugins={[remarkGfm]}
      // By default react-markdown will encode all urls for safety. If
      // transformed, image source urls won't work. Since we trust these
      // responses, this transformation is unnecessary. This function
      // overrides the default behavior by using the url as-is.
      urlTransform={(url) => url}
      components={{ a: LinkRenderer }}
    >
      {message}
    </Markdown> 
  )
}

type ItemsListProps = {
  message: string;
  includesProductCards: boolean;
}

// The following function is used to manipulate the message to render
// the chat items in case of Autonomous Buying
export function processItemsList({ message, includesProductCards} : ItemsListProps){
  if (includesProductCards) {
    const startPrefixRegex = /\*\*start_prefix\*\*([\s\S]*?)\*\*end_prefix\*\*/;
    const match = message.match(startPrefixRegex);
    let prefixString = '';
    if (match) {
      prefixString = match[1].trim();
      message = message.replace(startPrefixRegex, '');
    }
    const suffixRegex = /\*\*start_suffix\*\*([\s\S]*?)\*\*end_suffix\*\*/;
    const suffixMatch = message.match(suffixRegex);
    const suffixString = suffixMatch ? suffixMatch[1].trim() : '';
    const cleanedMarkdown = message.replace(/\*\*Image\*\*/g, '**Image**\n')
      .replace(suffixRegex, '');

    const firstItem = cleanedMarkdown.split("2.\n");
    const secondItem = cleanedMarkdown.split("2.\n");
    const thirdItem = cleanedMarkdown.split("3.\n");
        
    const itemsArray = [
      firstItem[0].split("1.\n")[1],
      secondItem[1].split("3.\n")[0],
      thirdItem[1],
    ];

    return { prefixString, suffixString, itemsArray };
  } else {
    return null
  };
}

/**
 * Renders a message in the chatbot's chat history (including messages from the
 * user, the bot, or the system).
 */
const ChatbotMessage = ({
  sender,
  message,
  children,
  includesProductCards = false,
  openInSameTab,
  onRetry,
  index,
}: ChatbotMessageProps) => {

  const processedItemsList = processItemsList({ message, includesProductCards });  
  return (
    <div
      className={`aiops-chatbot-message sender-${sender.toLowerCase()} col`}
    >
      {message && (
        <>
          {!includesProductCards ? (
            <CustomMarkdown message={message} openInSameTab={openInSameTab}/>
          ) : (
            <div className="conversational-ai-wrapper">
              <CustomMarkdown message={processedItemsList?.prefixString} openInSameTab={openInSameTab}/>
              <div className="conversational-ai-items-row ">
              {processedItemsList?.itemsArray.map((item, idx) => {
                return (
                  <div key={idx} className="conversational-ai-item">
                    <CustomMarkdown message={item} openInSameTab={openInSameTab}/>
                    <span>Enter {idx + 1} to select.</span>
                  </div>
                )
              })}
              </div>
              <CustomMarkdown message={processedItemsList?.suffixString} openInSameTab={openInSameTab}/>
            </div>
          )}
        </>
      )}
      {children}
      <ChatbotMessageFooter
        sender={sender}
        message={message}
        onRetry={onRetry}
        index={index}
      />
    </div>
  );
};

export default ChatbotMessage;
