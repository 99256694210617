import propTypes from "prop-types";
import { IconButton } from "@aiops/styleguide";
import trashIcon from "@/assets/icons/trashIcon.svg";
import "./UsecaseTableActions.scss";

const UsecaseTableActions = ({ microsolutionId = "", usecaseName = "", setSingleDeleteRow, handleDeleteAction }) => {
    return (
        <div className="usecase-table-actions">
            <IconButton className="clickable" onClick={() => {
                setSingleDeleteRow({
                    context: microsolutionId,
                    subcontext: usecaseName,
                });
                handleDeleteAction();
            }}>
                <img src={trashIcon} alt="delete" />
            </IconButton>
        </div>
    );
}

UsecaseTableActions.propTypes = {
    microsolutionId: propTypes.string.isRequired,
    usecaseName: propTypes.string.isRequired,
    setSingleDeleteRow: propTypes.func.isRequired,
    handleDeleteAction: propTypes.func.isRequired,
};

export default UsecaseTableActions;