import { useChatbotContext } from "../ChatbotContext";
import { useEffect } from "react";
import { chatbotContextChangeObject, contextPill, defaultContextMessageObject } from "../ChatbotMessage";
import { GenAIContextType, getContextMessageAndQueries } from "../chatbotApiHelper/apiHelper";
import "./ChatbotContextPrompt.scss";

/**
 * Renders a prompt to the user to pick a context.
 */
const ChatbotContextPrompt = () => {
  const {
    appId,
    appName,
    contexts,
    setSuggestedQueries,
    currentContext,
    setCurrentContext,
    addToChatHistory,
  } = useChatbotContext();  
  
  const onSetContext = (context: GenAIContextType) => {
    setCurrentContext(context);
    addToChatHistory(chatbotContextChangeObject(context.name));
  }

  const getDefaultMessage = async (context: string) => {
    const { usecaseMessage, usecaseQueries } = await getContextMessageAndQueries(appId, context);
    addToChatHistory(defaultContextMessageObject(usecaseMessage));
    setSuggestedQueries(usecaseQueries);
  }

  useEffect(() => {
    if (!currentContext && contexts.length === 1) {
      onSetContext(contexts[0]);
      getDefaultMessage(contexts[0].name);
    }
  }, [currentContext, contexts])

  return (
    currentContext
      ? null
      : <div className="aiops-chatbot-message context-prompt">
        <p>
          {`Hello, tell me how I can help you with ${appName}. Begin by selecting an option below.`}
        </p>
        <div className="chatbot-context-pill-wrapper">
          {contexts.map((c) => contextPill(c.name, () => {
            onSetContext(c);
            getDefaultMessage(c.name);
          }))}
        </div>
      </div>
  );
};

export default ChatbotContextPrompt;
