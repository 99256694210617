import React from "react";
import DataConnectionForm from "../DataConnectionForm";
import LlmConfigurationForm from "../LlmConfigurationForm";
import UseCaseOverviewForm from "../UseCaseOverviewForm";
import { DataConnectionFormType, UsecaseOverviewType, llmConfigurationFormType } from "../genaiSettingsHelper/genaiSettingsHelper";
import { UsecaseTabType } from "../genaiSettingsHelper/utils";
import GenericDataConnectionForm from "../GenericDataConnectionForm";

const TabToComponent = ({
  tabName,
  tabData,
  editable,
  onSave,
  disableChangeContext,
  setEditable,
  readOnlyDisabled,
  isSemanticStore,
  isGenericFunctions,
  usecaseType,
}: {
  tabName: string,
  tabData?: UsecaseOverviewType | DataConnectionFormType | llmConfigurationFormType;
  editable: boolean,
  onSave: (data: UsecaseOverviewType | Record<string, any>, tab: UsecaseTabType) => void,
  disableChangeContext: boolean,
  setEditable: (boolean) => void,
  readOnlyDisabled: boolean,
  isSemanticStore: boolean,
  isGenericFunctions: boolean,
  usecaseType: string,
}) => {

  if (tabName === "Overview Details") {
    return <UseCaseOverviewForm usecaseTabData={tabData as UsecaseOverviewType} editable={editable} onSave={onSave} disableChangeContext={disableChangeContext} setEditable={setEditable} readOnlyDisabled={readOnlyDisabled} />;
  } else if (tabName === "Data Connection") {
    if (isGenericFunctions || isSemanticStore) {
      return <GenericDataConnectionForm dataConnectionTabData={tabData as DataConnectionFormType[]} editable={editable} onSave={onSave} setEditable={setEditable} readOnlyDisabled={readOnlyDisabled} usecaseType={usecaseType} isSemanticStore={isSemanticStore}/>;
    } else {
      return <DataConnectionForm dataConnectionTabData={tabData as DataConnectionFormType[]} editable={editable} onSave={onSave} setEditable={setEditable} readOnlyDisabled={readOnlyDisabled} />;
    }
  } else if (tabName === "LLM Configuration") {
    return <LlmConfigurationForm llmConfigTabData={tabData as llmConfigurationFormType} editable={editable} onSave={onSave} setEditable={setEditable} readOnlyDisabled={readOnlyDisabled} isSemanticStore={isSemanticStore} />;
  } else {
    return <div></div>
  }
}

export default TabToComponent;
