export const endpoints = {
  PREFIX: "/genai",
  MICROSOLUTIONS: "/microsolutions",
  USECASES: "/usecases",
  PATCH_USECASE_STATUS: "/usecase/status",
  DELETE_USECASE: "/usecase/delete",
  USECASE_DETAILS: "/microsolution/usecase",
  REGISTER_USE_CASE: "/usecase/register",
  MASTER_DATA: "admin/master_data",
  TEST_CONNECTION: "connection/validate/",
}
