import { useEffect, useState } from "react";
import "./ChatbotWidget.scss";
import { useWindowSize, layoutSizes } from '@aiops/styleguide';
import ChatbotOpenIcon from "../ChatbotOpenIcon";
import ChatbotWindow from "../ChatbotWindow";
import ChatbotWrap from "../ChatbotWrap";
import { useChatbotContext } from "../ChatbotContext";

// This is only a separate, exported function to make testing easier.
export const getRightFloat = (screenWidth: number) => {
  if (screenWidth > layoutSizes.MAX_APP_WIDTH) {
    return "calc(var(--aiops-chatbot-margin) + calc((100vw - var(--max-app-width)) / 2))";
  } else {
    return "var(--aiops-chatbot-margin)";
  }
}

const ChatbotWidgetContents = ({ defaultOpen }) => {
  const { contexts } = useChatbotContext();
  const [chatbotOpen, setChatbotOpen] = useState<boolean>(defaultOpen);

  useEffect(() => {
    setChatbotOpen(defaultOpen);
  }, [defaultOpen]);

  return (
    contexts.length > 0 &&
    (
      chatbotOpen
        ? <ChatbotWindow
          closeWindow={() => setChatbotOpen(false)}
        />
        : <ChatbotOpenIcon
          openChatbot={() => setChatbotOpen(true)}
        />
    )
  )
}

/**
 * Type for props of ChatbotWidget component.
 */
export type ChatbotWidgetProps = {
  appId: string;
  defaultOpen?: boolean,
};

/**
 * Renders a widget that opens/closes the chatbot window.
 */
const ChatbotWidget = ({ appId, defaultOpen = false }: ChatbotWidgetProps) => {
  const { screenWidth } = useWindowSize();

  return (<ChatbotWrap appId={appId}>
    <div
      className="aiops-chatbot-widget-wrap"
      style={{
        right: getRightFloat(screenWidth),
      }}
    >
      {appId && <ChatbotWidgetContents defaultOpen={defaultOpen} />}
    </div>
  </ChatbotWrap>
  );
};

export default ChatbotWidget;
