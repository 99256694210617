import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./UsecaseDetailsStepper.scss";
import { Step, StepLabel, Stepper } from "@aiops/styleguide";
import { useGenAISettingsContext } from "@/context";
import { StepperStepType } from "@/components/genaiSettingsHelper/utils";

const UsecaseDetailsStepper = ({ activeStep, steps }) => {
  const [isOverviewDetailsStepComplete, setIsOverviewDetailsStepComplete] = useState(false);
  const [isDataConnectionStepComplete, setIsDataConnectionStepComplete] = useState(false);
  const [isLLMConfigurationStepComplete, setIsLLMConfigurationStepComplete] = useState(false);
  const { isUsecaseDetailsFormValid } = useGenAISettingsContext();

  useEffect(() => {
    setIsOverviewDetailsStepComplete(isUsecaseDetailsFormValid.overviewDetailsForm);
  }, [isUsecaseDetailsFormValid.overviewDetailsForm]);

  useEffect(() => {
    setIsDataConnectionStepComplete(isUsecaseDetailsFormValid.dataConnectionForm);
  }, [isUsecaseDetailsFormValid.dataConnectionForm]);

  useEffect(() => {
    setIsLLMConfigurationStepComplete(isUsecaseDetailsFormValid.llmConfigurationForm);
  }, [isUsecaseDetailsFormValid.llmConfigurationForm]);

  const isStepComplete = (step: StepperStepType) => {
    if (
      (step.form === "overview_details" && !isOverviewDetailsStepComplete) ||
      (step.form === "data_connection_details" && !isDataConnectionStepComplete) ||
      (step.form === "llm_configuration_details" && !isLLMConfigurationStepComplete)
    ) {
      return false;
    }
  };

  return (
    <div className="stepper-container">
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((step: StepperStepType) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          stepProps.completed = isStepComplete(step);

          return (
            <Step key={step.label} {...stepProps}>
              <StepLabel {...labelProps}>{step.label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
};

UsecaseDetailsStepper.propTypes = {
  activeStep: PropTypes.number.isRequired,
  steps: PropTypes.array.isRequired,
};

export default UsecaseDetailsStepper;
