import { useEffect } from "react";
import "./UsecaseFormWithCustomFooter.scss";
import { DataConnectionFormType, UsecaseOverviewType, llmConfigurationFormType } from "../genaiSettingsHelper/genaiSettingsHelper";
import { UsecaseTabType } from "@/components/genaiSettingsHelper/utils";
import { useNavigate } from "react-router-dom";
import { UsecaseConfirmationModalObjectType, useGenAISettingsContext, UsecaseConfirmationModalButtonType } from "@/context";
import { Form, StickyAppFooter, Typography, closeIcon, Button } from "@aiops/styleguide";
import editIcon from "../../assets/icons/editIcon.svg";


export type FormType = {
  // tabData: UsecaseOverviewType | DataConnectionFormType
  usecaseTabData?: UsecaseOverviewType;
  dataConnectionTabData?: DataConnectionFormType[];
  llmConfigTabData?: llmConfigurationFormType;
  editable?: boolean;
  onSave?: (data: UsecaseOverviewType | Record<string, any>, tab: UsecaseTabType) => void;
  disableChangeContext?: boolean;
  setEditable: (boolean) => void,
  readOnlyDisabled: boolean,
  isSemanticStore?: boolean,
  usecaseType?: string,
}


export type DropDownType = {
  label: string;
  value: string;
}

const UsecaseFormWithCustomFooter = ({
  children,
  onSubmit,
  editable,
  saveDisabled,
  setEditable,
  readOnlyDisabled,
  reset,
}: {
  children: JSX.Element,
  onSubmit: () => {},
  editable: boolean,
  saveDisabled: boolean,
  setEditable: (arg0: boolean) => void,
  readOnlyDisabled: boolean,
  reset: () => void,
}) => {
  const navigate = useNavigate();
  const { isUsecaseDetailsUnsaved, setIsUsecaseConfirmationModalOpen, usecaseConfirmationModalButtonClicked, setUsecaseConfirmationModalButtonClicked } = useGenAISettingsContext();
  
  // Reset the form only when the user clicks on the yes button in the confirmation modal
  useEffect(() => {
    if(usecaseConfirmationModalButtonClicked === "yes") {
      reset();
    }
    return () => {
      setUsecaseConfirmationModalButtonClicked("" as UsecaseConfirmationModalButtonType);
    }
  }, [usecaseConfirmationModalButtonClicked]);

  const handleClick = () => {
    if(editable && isUsecaseDetailsUnsaved) {
      setIsUsecaseConfirmationModalOpen({isOpen:true, triggerPoint: 'cancel-btn-click'} as UsecaseConfirmationModalObjectType);
    } else {
      setEditable(!editable);
    }
  }

  const handleCancel = () => {
    if(!isUsecaseDetailsUnsaved) {
      navigate("/")
    } else {
      setIsUsecaseConfirmationModalOpen({isOpen:true, triggerPoint: 'back-btn-click'} as UsecaseConfirmationModalObjectType);
    }
  }

  return (
    <div className="usecase-details-form-wrapper">
      <Form
        onSubmit={() => {}}
        showFooter={false}
        width={"var(--max-app-width)"}
        cardProps={{
          style: {
            padding: "24px",
            flexWrap: "wrap",
          }
        }}
      >
        <div>
          <div className="edit-btn row">
            <div className="clickable">
              {!editable ? (
                <Typography variant="paragraph1" onClick={handleClick}>
                  <img src={editIcon} alt="edit" className="cancel-icon"/> Edit
                </Typography>
                ):
                <>
                  {!readOnlyDisabled &&
                    <Typography variant="paragraph1" onClick={handleClick}>
                      <img src={closeIcon} alt="cancel" className="cancel-icon"/> Cancel
                    </Typography>
                  }
                </>
              }
            </div>
          </div>
          {children}
        </div>
      </Form>
      <StickyAppFooter>
        <Button variant="outlined" className="usecase-details-footer-back-btn" onClick={handleCancel}>Back to GenAI Settings</Button>
        <Button variant="filled" className="usecase-details-footer-save-btn" onClick={onSubmit} disabled={saveDisabled}>Save</Button>
      </StickyAppFooter>
    </div>
  );
}

export default UsecaseFormWithCustomFooter;