import { useState } from 'react';
import "./ChatbotWindow.scss";
import { useWindowSize } from '@aiops/styleguide';
import ChatbotHeader from '../ChatbotHeader';
import ChatbotChat from '../ChatbotChat';
import { useChatbotContext } from '../ChatbotContext';

/**
 * Type for props of ChatbotWindow component.
 */
export type ChatbotWindowProps = {
  closeWindow: () => void;
};

/**
 * Container that holds all chatbot window content.
 */
const ChatbotWindow = ({ closeWindow }: ChatbotWindowProps) => {
  const { expanded, setExpanded } = useChatbotContext();
  const { appWidth } = useWindowSize();
  

  return (
    <div
      className="aiops-chatbot-window col"
      style={{
        width: expanded ? `calc(${appWidth}px - var(--aiops-chatbot-margin)` : 'var(--aiops-chatbot-window-default-width)',
        maxWidth: 'min(calc(100vw - calc(2 * var(--aiops-chatbot-margin))), var(--aiops-chatbot-window-max-width))',
      }}
    >
      <ChatbotHeader
        isExpanded={expanded}
        setIsExpanded={setExpanded}
        closeWindow={closeWindow}
      />
      <ChatbotChat />
    </div>
  );
};

export default ChatbotWindow;