//Mapping of microsolution field and display name
export const columns = [
  {
    field: 'usecase_name',
    title: 'Use Case Name',
  },
  {
    field: 'microsolution_name',
    title: 'Microsolution Name',
  },
  {
    field: 'usecase_description',
    title: 'Description',
  },
  {
    field: 'usecase_status',
    title: 'Status',
  },
  {
    field: 'actions',
    title: 'Actions',
  }
];

export const USE_CASE_PAGE_SIZE_OPTIONS = [
  10,
  20,
  50,
  100,
]
