import React, { useState } from "react";
import "./MicrosolutionTable.scss";
import { Tabs, Tab, Typography, Retry, Button, StyledSwitch, Modal, ConfirmationModal } from "@aiops/styleguide";
import activateSvg from "../../assets/icons/activate.svg";
import deactivateSvg from "../../assets/icons/deactivate.svg";
import trashIcon from "../../assets/icons/trashIcon.svg";
import warningIcon from "../../assets/icons/warningIcon.svg";
import UseCaseTable from "../UseCaseTable";

export type StatusFieldType = {
  usecase_status: "Active" | "Inactive" | "Draft";
  microsolution_id: string;
  usecase_name: string;
  updateStatus: (
    context: string,
    subcontext: string,
    usecase_status: "deactivate" | "activate",
  ) => void;
}

export type TableProps = {
  active: boolean;
  setDraftCount?: Function;
  tableType: "Registered" | "Draft";
}

export const StatusField = ({
  usecase_status,
  microsolution_id,
  usecase_name,
  updateStatus,
}: StatusFieldType) => {
  const [checked, setChecked] = useState<boolean>(usecase_status === "Active");

  if (usecase_status === "Draft") {
    return <Typography variant="caption-italic">{usecase_status}</Typography>
  }

  const handleToggle = () => {
    setChecked(!checked);
    updateStatus(
      microsolution_id,
      usecase_name,
      usecase_status === "Active" ? "deactivate" : "activate"
    );
  }

  return (
    <StyledSwitch
      checked={checked}
      onChange={handleToggle}
      ref={null}
    />
  )
}

export type TableActionsRowType = {
  tableType: "Registered" | "Draft";
  handleClickAction: (event: React.SyntheticEvent) => void;
  handleDeleteAction: (event: React.SyntheticEvent) => void;
  handleRefresh: (event: React.SyntheticEvent) => void;
  error: string;
  disabled: boolean;
}

export const TableActionsRow = ({
  tableType,
  handleClickAction,
  handleDeleteAction,
  handleRefresh,
  error = "",
  disabled,
}: TableActionsRowType) => {
  return (
    <div className="flex row table-actions">
      <div className="patch-actions row">
        {tableType === "Registered" && (
          <>
            <Button className="action-btn clickable" id="activate" onClick={handleClickAction} disabled={disabled}>
              <img src={activateSvg} alt="activate" />Activate
            </Button>
            <Button className="action-btn clickable" id="deactivate" onClick={handleClickAction} disabled={disabled}>
              <img src={deactivateSvg} alt="deactivate" />Deactivate
            </Button>
          </>
        )}
        <Button className="action-btn clickable" id="Delete" onClick={handleDeleteAction} disabled={disabled}>
          <img src={trashIcon} alt="delete" />Delete
        </Button>
        {error && (
          <div className="action-err flex row">
            <img src={warningIcon} alt="warning" />{error}
          </div>
        )}
      </div>
      <Button className="refresh-btn clickable" onClick={handleRefresh}>
        <Retry textToDisplay=" "/>
      </Button>
    </div>
  );
}

export const ConfirmDeleteModal = ({ open, onClose, onCancel, onConfirm, singleUseCase = true }: {
  open: boolean,
  onClose: () => void,
  onCancel: () => void,
  onConfirm: () => void,
  singleUseCase?: boolean,
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <ConfirmationModal
        headlineText="Remove Use Case"
        cancelButtonText="Cancel"
        confirmationButtonText="Remove"
        onCancel={onCancel}
        onConfirm={onConfirm}
      >
        <Typography variant="paragraph1-light">
          Are you sure you want to remove {singleUseCase ? "this use case" : "these use cases"}?<br />
          This action cannot be undone.
        </Typography>
      </ConfirmationModal>
    </Modal>
  );
}

const MicrosolutionTable = () => {
  const [value, setValue] = useState<number>(0);
  const [draftCount, setDraftCount] = useState<number>(null);
  const draftCountText = draftCount ? ` (${draftCount})` : '';

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  }

  return (
    <div className="genai-usecase-table-wrapper">
      <Tabs value={value} onChange={handleTabChange}>
        <Tab label="Registered" className="genai-usecase-table-tab" />
        <Tab label={`Draft${draftCountText}`} className="genai-usecase-table-tab" />
      </Tabs>
      <UseCaseTable active={value === 0} setDraftCount={null} tableType="Registered"/>
      <UseCaseTable active={value === 1} setDraftCount={setDraftCount} tableType="Draft"/>
    </div>
  );
}

export default MicrosolutionTable;
