export const endpoints = {
    PREFIX: "/genai",
    QUERY: "/query",
    LEX_QUERY: "/lex/query",
    CONTEXTS: "/microsolution/usecases",
    DEFAULT_CONTEXT_MESSAGE: "/usecase/default_message",
    STREAMING_QUERY: "/query/stream",
    TRANSCRIBE: "/transcribe",
}

export const defaultPrompts = {
    RETRY: "I don't like this response. Please try again.",
}

export const GENERIC_ERROR_MSG = "Sorry, something went wrong. Please re-submit your request to try again."

