import React, { useEffect } from "react";
import { AudioRecorder as ReactAudio, useAudioRecorder } from "react-audio-voice-recorder";
import "./AudioRecorder.scss";

type AudioRecorderProps = {
  addAudioElement: (blob: Blob) => void;
  isRecording: boolean;
}

/**
 * 
 * @param addAudioElement
 * Callback to handle the audio blob
 * @param isRecording
 * Boolean to indicate if the recording is in progress
 * @returns 
 */
const AudioRecorder = ({
  addAudioElement,
  isRecording,
}: AudioRecorderProps) => {
  const recorderControls = useAudioRecorder();
  const { startRecording, stopRecording } = recorderControls;

  useEffect(() => {
    isRecording ? startRecording() : stopRecording();
  }, [isRecording]);

  return (
    <div>
      <ReactAudio
        onRecordingComplete={addAudioElement}
        recorderControls={recorderControls}
        // Custom class for the recorder -
        // This is set to display: none in the css since AIOPS.D will be using a custom
        // button to trigger the recording. This is just to ensure the recorder is mounted
        // and ready to record. Additionally, the icons which ReactAudio uses in it's UI
        // are not implemented, hence they all show alt text always.
        classes={{
          AudioRecorderClass: "recorder-parent",
        }}
      />
    </div>
  );
};

export default AudioRecorder;
