import "./ChatbotOpenIcon.scss";
import { genaiIcon } from "@aiops/styleguide";

/**
 * Type for props of ChatbotOpenIcon component.
 */
export type ChatbotOpenIconProps = {
  openChatbot: () => void;
};

/**
 * Renders the icon/button to open the chatbot.
 */
const ChatbotOpenIcon = ({ openChatbot }: ChatbotOpenIconProps) => {
  return (
    <button
      data-testid="chatbot-open-icon"
      onClick={openChatbot}
      className="aiops-chatbot-open-icon"
    >
      <img src={genaiIcon} alt="Open Chatbot" />
    </button>
  );
};

export default ChatbotOpenIcon;
