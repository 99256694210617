import { useEffect, useState, useRef } from "react";
import "./ChatbotChat.scss";
import { useChatbotContext } from "../ChatbotContext";
import { getChatbotResponse, getChatbotStreamResponse } from '../chatbotApiHelper';
import {
  CircularProgress,
  SendIcon,
  CheckIcon,
  useResizableTextArea,
  Typography,
  MicIcon,
} from "@aiops/styleguide"
import ChatbotMessage, { ChatbotMessageProps, getInvalidResponseObject } from "../ChatbotMessage";
import ChatbotContextPrompt from "../ChatbotContextPrompt";
import { defaultPrompts, GENERIC_ERROR_MSG } from "../chatbotApiHelper/constants";
import { responseTypes, DISCLAIMER } from '../../constants';
import SuggestedQueryContainer from "../SuggestedQueryContainer";
import { GenAIContextType, getVoiceTranscription } from "../chatbotApiHelper/apiHelper";
import AudioRecorder from "../AudioRecorder";


/**
 * Returns the appropriate ChatbotMessage object depending on the response's
 * response_type.
 *  
 * @param raw 
 * Raw response from the chatbot API.
 * 
 * @param currentContext 
 * The current context (string)
 * 
 * @param contexts 
 * List of all contexts (strings)
 */
export const messageObject = (
  raw: Record<any, any>,
  currentContext: GenAIContextType,
  contexts: GenAIContextType[],
  setCurrentContext: (s: GenAIContextType) => void,
  addToChatHistory: (m: ChatbotMessageProps) => void,
): ChatbotMessageProps => {
  const message = raw?.llm_response || GENERIC_ERROR_MSG;
  if (raw?.response_type === responseTypes.VALID) {
    return {
      sender: 'BOT',
      message,
      includesProductCards: raw?.includes_cards || false,
      openInSameTab: raw?.open_in_same_tab || false,
    }
  } else if (raw?.response_type === responseTypes.INVALID) {
    const onSetContext = (context: GenAIContextType, message: ChatbotMessageProps) => {
      setCurrentContext(context);
      addToChatHistory(message);
    }
    return getInvalidResponseObject(message, currentContext, contexts, onSetContext)
  }
  throw new Error(`Unexpected response type: ${raw.response_type}`);
}

/**
 * Renders the chat history and input for the chatbot.
 */
const ChatbotChat = () => {
  const [userInput, setUserInput] = useState<string>('');
  const [isStreamingEnabled, setIsStreamingEnabled] = useState<boolean>(false);
  const [streamResponse, setStreamResponse] = useState("");
  const [isRecording, setIsRecording] = useState<boolean>(false);

  const {
    appId,
    currentContext,
    contexts,
    setCurrentContext,
    awaitingResponse,
    setAwaitingResponse,
    sessionId,
    chatHistory,
    addToChatHistory,
  } = useChatbotContext();

  const addAudioElement = (blob: Blob) => {
    setAwaitingResponse(true);
    const audioBlob = new Blob([blob], { type: "audio/mpeg" });
    const reader = new FileReader();
    reader.readAsDataURL(audioBlob);
    reader.onloadend = function () {
      const base64data = reader.result.toString().replace(/^data:.+;base64,/, '');
      getVoiceTranscription(appId, sessionId, currentContext.name, base64data).then((res) => {
        if (res?.transcription) {
          setUserInput(res.transcription);
        } else {
          addToChatHistory({
            sender: "BOT",
            message: "Sorry, I couldn't understand that. Please try again."
          })
        }
      }).catch((err) => {
        console.error("Error getting voice transcription", err);
      }).finally(() => {
        setAwaitingResponse(false);
        selectAndFocusInput();
      });
    }
  }

  const handleMicrophoneClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setUserInput('');
    isRecording ? setIsRecording(false) : setIsRecording(true);
  }

  const chatHistoryRef = useRef();
  const chatInputRef = useRef<HTMLTextAreaElement>();

  useResizableTextArea({
    textAreaRef: chatInputRef.current,
    value: userInput,
    maxNumberOfLines: 3.5,
    lineHeight: "1.5em"
  });

  // Selects the input and focuses it.
  const selectAndFocusInput = () => {
    const inputEl = chatInputRef.current;
    if (inputEl) {
      // @ts-ignore
      inputEl.focus();
      // @ts-ignore
      inputEl.select();
    }
  }

  // Scrolls the chat history to the bottom.
  const autoScrollToBottom = () => {
    const chatHistoryEl = chatHistoryRef.current;
    if (chatHistoryEl) {
      // @ts-ignore
      chatHistoryEl.scrollTop = chatHistoryEl.scrollHeight;
    }
  }

  // Start with the input selected so the user can immediately type.
  useEffect(() => {
    selectAndFocusInput();
  }, []);

  // Scroll to the bottom of the chat history when it changes, or when the
  // loading UI starts/ends or when the stream response is updated.
  useEffect(() => {
    autoScrollToBottom();
  }, [chatHistory, awaitingResponse, streamResponse]);

  useEffect(() => {
    if (currentContext) {
      setIsStreamingEnabled(currentContext.streaming_enabled);
    } else {
      setUserInput('');   // Clear the input when the chat is refreshed.
    }
  }, [currentContext]);

  const placeholderText = () => {
    if (!currentContext) {
      return "Make a selection above to begin chatting"
    } else if (awaitingResponse) {
      return "A response is being generated"
    } else if (isRecording) {
      return "Recording..."
    }
    return "Ask a question"
  }

  // Handles the form submission by clearing the input and calling the onSubmit
  // function with the user's input as the query.
  const handleFormSubmission = (e: React.FormEvent) => {
    e.preventDefault();
    isStreamingEnabled ? submitStreamQuery(userInput) : submitQuery(userInput);
    setUserInput('');
  }

  // Submits a query to the chatbot API and adds the response to the chat history.
  const submitQuery = (query: string) => {
    setAwaitingResponse(true);
    addToChatHistory({
      sender: 'USER',
      message: query,
    })
    getChatbotResponse(
      appId,
      sessionId,
      currentContext.name,
      query,
    ).then((res) => {
      addToChatHistory(messageObject(res, currentContext, contexts, setCurrentContext, addToChatHistory));
    }).catch((e) => {
      console.error("Error getting chatbot response", e);
      addToChatHistory({
        sender: 'BOT',
        message: GENERIC_ERROR_MSG,
      });
    }).finally(() => {
      setAwaitingResponse(false);
      selectAndFocusInput();
    });
  }

  // Submits a event stream query to the chatbot API
  const submitStreamQuery = (query: string) => {
    setAwaitingResponse(true);
    addToChatHistory({
      sender: 'USER',
      message: query,
    });
    getChatbotStreamResponse(
      appId,
      sessionId,
      currentContext,
      query,
      setStreamResponse,
      addToChatHistory,
    )
    .catch((e) => {
      console.error("Error getting chatbot response", e);
      addToChatHistory({
        sender: 'BOT',
        message: GENERIC_ERROR_MSG,
      });
    })
    .finally(() => {
      setAwaitingResponse(false);
      selectAndFocusInput();
    });
  }

  const disabledClassOrNot = () => {
    if (awaitingResponse || !currentContext || isRecording) {
      return "disabled";
    }
    return "";
  }

  // Returns a row div with the message and (if the message is from the user)
  // either a checkmark or a loading icon (loading if it's the last message
  // and awaitingResponse is true)
  const messageAndIcon = (msg: ChatbotMessageProps, idx: number, icon: null | JSX.Element) => (
    <div
      key={idx}
      className={`message-row ${msg.sender == "BOT" ? "bot" : "user"}`}
    >
      {icon}
      <ChatbotMessage
        {...msg}
        onRetry={() => isStreamingEnabled ? submitStreamQuery(defaultPrompts.RETRY) : submitQuery(defaultPrompts.RETRY)}
        index={idx}
      />
    </div>
  )

  // Returns the icon that should accompany the message (if any)
  const getIcon = (msg: ChatbotMessageProps, isLast: boolean) => {
    if (msg.sender === "BOT") {
      return null;
    }
    return (
      <div className={`icon`}>
        {isLast && awaitingResponse
          ? <CircularProgress size="24px" />
          : <CheckIcon className="check" />
        }
      </div>
    )
  }

  return (
    <div className="col aiops-chatbot-chat">
      <div
        className="aiops-chatbot-chat-history"
        ref={chatHistoryRef}
      >
        {
          chatHistory.map((msg, i) => (
            messageAndIcon(msg, i, getIcon(msg, i === chatHistory.length - 1))
          ))
        }
        {isStreamingEnabled && streamResponse && awaitingResponse && (
          <ChatbotMessage sender="BOT" message={streamResponse} />
          )}
        {<ChatbotContextPrompt />}
      </div>
      <SuggestedQueryContainer
        onSubmit={isStreamingEnabled ? submitStreamQuery : submitQuery}
        disabled={awaitingResponse}
      />
      <form
        onSubmit={handleFormSubmission}
        className={`aiops-chatbot-chat-input-wrap row ${disabledClassOrNot()}`}
      >
        <textarea
          disabled={awaitingResponse || !currentContext || isRecording}
          className={`aiops-chatbot-chat-input ${awaitingResponse || !currentContext || isRecording ? "disabled" : ""}`}
          placeholder={placeholderText()}
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          ref={chatInputRef}
          onKeyDown={(e: React.KeyboardEvent<HTMLTextAreaElement>) => {
            if (e.key === "Enter") {
              if (!e.shiftKey) {
                handleFormSubmission(e);
              } else {
                e.preventDefault();
              }
            }
          }}
        />
        <button
          disabled={awaitingResponse || !currentContext}
          className={`voice-btn ${disabledClassOrNot()} ${isRecording && "recording"}`}
          onClick={handleMicrophoneClick}
          data-testid="voice-btn"
        >
          <MicIcon />
          <AudioRecorder addAudioElement={addAudioElement} isRecording={isRecording} />
        </button>
        <button
          disabled={awaitingResponse || !currentContext || !userInput}
          type="submit"
          className={`aiops-chatbot-chat-submit-button ${disabledClassOrNot()}`}
        >
          <SendIcon />
        </button>
      </form>
      {currentContext && (
        <div className="flex row disclaimer">
          <Typography variant="caption1e">
            {DISCLAIMER}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default ChatbotChat;
