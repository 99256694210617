import React, { useEffect, useState } from "react";
import { Table, useToast } from "@aiops/styleguide";
import { DeleteUsecaseStatus, DetailedUsecaseType, UpdateUsecaseStatus } from "../genaiSettingsHelper/genaiSettingsHelper";
import { columns } from "../MicrosolutionTable/config";
import { ConfirmDeleteModal, StatusField, TableActionsRow, TableProps } from "../MicrosolutionTable";
import { DEFAULT_FIRST_PAGE, getTableData, getTablePagination, getTableSelectability, handleDeleteUsecases, handleTablePatch } from "../MicrosolutionTable/utils";
import UsecaseNameLink from "./UsecaseNameLink";
import UsecaseTableActions from "./UsecaseTableActions";

const UseCaseTable = ({ active, setDraftCount, tableType }: TableProps) => {
  const [tableData, setTableData] = useState<DetailedUsecaseType[]>([]);
  const [selectedData, setSelectedData] = useState<UpdateUsecaseStatus[]>([]);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [tableError, setTableError] = useState<string>(null);
  const [singleDeleteRow, setSingleDeleteRow] = useState<UpdateUsecaseStatus>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [availablePages, setAvailablePages] = useState<Record<number, string>>(DEFAULT_FIRST_PAGE);  

  const { setCurrentToast } = useToast();

  const handleGetTableData = () => {
    getTableData(
      tableType === "Registered" ? ["Active", "Inactive"] : ["Draft"],
      setTableLoading,
      setTableData,
      setTableError,
      setTotalCount,
      (index) => {
        if (index) {
          const pages = {...availablePages};
          pages[currentPage + 1] = index
          setAvailablePages(pages);
        }
      },
      availablePages[currentPage],
      rowsPerPage,
    )
  }

  useEffect(() => {
    handleGetTableData();
  }, [currentPage, rowsPerPage]);

  useEffect(() => {
    if (setDraftCount) {
      setDraftCount(totalCount);
    }
  }, [totalCount])

  const headerCells: Record<string, any> = {};

  columns.forEach((col) => {
    const field = col.field;
    headerCells[field] = {
      field,
      sortField: field,
      title: col.title,
      isCurrentSortColumn: false,
    }
  });

  const handleClickAction = (e: React.SyntheticEvent) => {
    handleTablePatch(
      e.currentTarget.id,
      selectedData,
      setTableError,
      setSelectedData,
      setCurrentToast,
      handleGetTableData,
    );
  }

  const handleToggleAction = (context: string, subcontext: string, status: string) => {
    handleTablePatch(
      status,
      [{ context: context, subcontext: subcontext}],
      setTableError,
      setSelectedData,
      setCurrentToast,
      handleGetTableData,
    )
  }

  const handleDeleteAction = (e?: React.SyntheticEvent) => {
    if (e && selectedData.length > 0 || !e) {
      setModalOpen(true);
    } else {
      setTableError("Please select a use case to delete.")
    }
  }

  const confirmDelete = () => {
    const data: DeleteUsecaseStatus[] = [];
    if (singleDeleteRow) {
      data.push({
        context: singleDeleteRow.context,
        subcontext: singleDeleteRow.subcontext,
      });
    } else {
      selectedData.forEach((val) => {
        data.push({
          context: val.context,
          subcontext: val.subcontext,
        });
      });
    };
    handleDeleteUsecases(
      data,
      setCurrentToast,
      handleGetTableData,
    ).finally(() => {
      setSingleDeleteRow(null);
      setSelectedData([]);
      setModalOpen(false);
    });
  }

  return (
    <>
      {active ? <div>
        <TableActionsRow
          handleClickAction={handleClickAction}
          handleDeleteAction={handleDeleteAction}
          handleRefresh={handleGetTableData}
          tableType={tableType}
          error={tableError}
          disabled={tableData?.length === 0}
        />
        <ConfirmDeleteModal
          open={modalOpen}
          onCancel={() => {
            setSingleDeleteRow(null);
            setModalOpen(false);
          }}
          onClose={() => {
            setSingleDeleteRow(null);
            setModalOpen(false);
          }}
          onConfirm={confirmDelete}
          singleUseCase={singleDeleteRow ? true : false}
        />
        <Table
          columnKeys={columns.map((col) => col.field)}
          headerCells={headerCells}
          tableIsLoading={tableLoading}
          emptyTableMessage={tableError || "No use cases found."}
          selectability={getTableSelectability({tableData, selectedData, setSelectedData, setError: setTableError})}
          rows={tableData.map((e) => ({
            usecase_name: <UsecaseNameLink 
              usecaseName={e.usecase_name || ""} 
              microsolutionId={e.microsolution_id || ""}
            />,
              usecase_status: <StatusField
              microsolution_id={e.microsolution_id}
              usecase_name={e.usecase_name}
              usecase_status={e.usecase_status}
              updateStatus={handleToggleAction}
              />,
              usecase_description: e.usecase_description,
              microsolution_name: e.microsolution_name,
              microsolution_id: e.microsolution_id,
              actions: <UsecaseTableActions 
                usecaseName={e.usecase_name || ""} 
                microsolutionId={e.microsolution_id || ""} 
                setSingleDeleteRow={setSingleDeleteRow} 
                handleDeleteAction={handleDeleteAction} />,
          }))}
          pagination={getTablePagination(
            currentPage,
            rowsPerPage,
            availablePages,
            setCurrentPage,
            setRowsPerPage,
            totalCount,
          )}
        />
      </div> : <></>}
    </>
  );
}

export default UseCaseTable;
