import React from "react";
import PropTypes from "prop-types";
import { Tabs, Tab } from "@aiops/styleguide";
import "./UsecaseDetailsTabs.scss";

const UsecaseDetailsTabs = ({ activeStep, steps, handleTabChange }) => {
  return (
    <Tabs value={activeStep} onChange={handleTabChange}>
      {steps.map((label: string) => {
        return <Tab label={label} key={label} className="usecase-detail-tab" />;
      })}
    </Tabs>
  );
};

UsecaseDetailsTabs.propTypes = {
  activeStep: PropTypes.number.isRequired,
  steps: PropTypes.array.isRequired,
  handleTabChange: PropTypes.func.isRequired,
};

export default UsecaseDetailsTabs;
