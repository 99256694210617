import { UsecaseDetailsType, UseCaseType } from "./genaiSettingsHelper";

type DataStoreConfigType = {
  connection_type?: string;
  API_ENDPOINT?: string;
  API_METHOD?: "GET" | "POST";
  DYNAMODB_TABLE_NAME?: string;
  AWS_REGION?: string;
  DYNAMODB_FILTERS?: string;
  FILE_STORE?: string;
  S3_BUCKET_NAME?: string;
  S3_KEY?: string;
  DATABASE_NAME?: string;
  COLLECTION_NAME?: string;
  CONNECTION_URL_SECRET?: string;
  DATA_LIMIT?: string;
  DATA_FIELD_NAME_FOR_N_DAYS_DATA_FETCH?: string;
  IS_DATA_FIELD_NAME_FOR_N_DAYS_DATA_FETCH_STRING?: string;
  N_DAYS_TO_FETCH_DATA?: string;
  DEFAULT_SORT_COLUMN?: string;
  CONNECTION_ERROR_MESSAGE?: string;
  DATABASE_TYPE?: string;
  DATABASE_HOST?: string;
  DATABASE_USERNAME_SECRETNAME?: string;
  DATABASE_PASSWORD_SECRETNAME?: string;
}

type PreDefinedQueryType = {
  title: string;
  description: string;
}

export type UpdateUsecaseDetailsType = {
  // overview details
  context: string;
  subcontext: string;
  usecase_type?: string;
  usecase_description?: string;
  prompt_template?: string;
  streaming_enabled?: boolean;
  // data store configuration
  data_store_config: DataStoreConfigType;
  tools_config?: Record<string, any>[];
  // llm configuration
  semantic_search_index?: string;
  semantic_store?: string;
  usecase_default_message?: string;
  usecase_predefined_queries?: PreDefinedQueryType[];
  llm_text_model_type?: string;
  llm_model_azure_deployment_name?: string;
  embedding_model_azure_deployment_name?: string;
  llm_model_azure_api_version?: string;
  llm_model_azure_model_version?: string;
  invalid_query_response_message?: string;
  llm_deployment_platform?: string;
  llm_model_name?: string;
  embedding_model?: string;
  embedding_platform?: string;
  isNewUsecase: boolean;
};

export type StepperStepType = {
  id: number;
  label: string;
  form: UsecaseTabType;
};

export type UsecaseTabType = "overview_details" | "data_connection_details" | "llm_configuration_details";

export function formatUsecaseDataToApiPayload(formData: UsecaseDetailsType): UpdateUsecaseDetailsType {
  const payload: UpdateUsecaseDetailsType = {
    context: formData.overview_details.microsolution_id,
    subcontext: formData.overview_details.usecase_name,
    data_store_config: {},
    isNewUsecase: formData.isNewUsecase
  };
  // remaining use case overview data
  payload["usecase_type"] = formData.overview_details.usecase_type;
  payload["usecase_description"] = formData.overview_details.usecase_description;
  payload["prompt_template"] = formData.overview_details?.usecase_prompt;
  payload["streaming_enabled"] = formData.overview_details?.streaming_enabled;
  // tools configuration
  payload["tools_config"] = formData.data_connection_details?.map((connection) => {
    return {
      tool_name: connection.tool_name,
      tool_description: connection.tool_description,
      tool_type: connection.tool_type,
      connection_name: connection.connection_name,
      data_store_config: {
        connection_type: connection.connection_type,
        API_ENDPOINT: connection.api_endpoint,
        API_METHOD: connection.api_method,
        FILE_STORE: connection.file_store,
        S3_BUCKET_NAME: connection.bucket_name,
        S3_KEY: connection.object_key,
        DATABASE_NAME: connection.database_name,
        COLLECTION_NAME: connection.collection_name,
        DATABASE_TYPE: connection.database_type,
        DATABASE_HOST: connection.database_host,
        DATA_LIMIT: connection.data_limit,
        N_DAYS_TO_FETCH_DATA: connection.n_days_to_fetch_data,
        DEFAULT_SORT_COLUMN: connection.db_sort_field_name,
        DATABASE_USERNAME_SECRETNAME: connection.database_user_secret_name,
        DATABASE_PASSWORD_SECRETNAME: connection.database_password_secret_name,
        CONNECTION_URL_SECRET: connection.connection_url_secret_name,
      },
      semantic_store_config: {
        // added flag since the GET API returns the semantic_store_index as semantic_search_index
        // @ts-ignore
        semantic_search_index: connection.semantic_search_index || connection.semantic_store_index,
        semantic_store: connection.semantic_store,
      }
    };
  })
  // llm configuration
  payload["usecase_default_message"] = formData.llm_configuration_details?.default_usecase_info_message || formData?.llm_configuration_details?.default_usecase_info_msg;
  payload["usecase_predefined_queries"] = formData.llm_configuration_details?.default_usecase_sample_query;
  payload["llm_text_model_type"] = formData.llm_configuration_details?.llm_model_provider;
  payload["llm_model_azure_deployment_name"] = formData.llm_configuration_details?.llm_azure_deployment_name;
  payload["embedding_model_azure_deployment_name"] = formData.llm_configuration_details?.embedding_model_azure_deployment;
  payload["llm_model_azure_api_version"] = formData.llm_configuration_details?.llm_azure_api_version;
  payload["llm_model_azure_model_version"] = formData.llm_configuration_details?.llm_azure_model_version;
  payload["invalid_query_response_message"] = formData.llm_configuration_details?.invalid_query_response_message;
  payload["llm_deployment_platform"] = formData.llm_configuration_details?.llm_deployment_platform;
  payload["llm_model_name"] = formData.llm_configuration_details?.llm_model_name;
  payload["embedding_model"] = formData.llm_configuration_details?.embedding_model;
  payload["embedding_platform"] = formData.llm_configuration_details?.embedding_platform || formData?.llm_configuration_details?.embedding;


  return payload;
}

// Function to check whether the required field values are not null in the object
export const checkRequiredFields = (data: Object, requiredFields: string[]) => {
  return requiredFields.every((field) => {
    return data[field] !== null && data[field] !== undefined && data[field] !== '' && data[field]?.length > 0 && Object.keys(data[field])?.length > 0;
  });
}

/* Function to get count of usecases */
export const getUsecaseCount = (usecases: UseCaseType[]) => {
  if (usecases?.length) {
    return usecases.length;
  } else {
    return 0;
  }
}
/* Function to get the URL when navigating to use case details from usecase table */
export const getNavigateURL = ({ microsolution, usecase }) => {
  const id = microsolution && `/${microsolution}`;
  const name = usecase && `/${usecase}`;
  return `usecase${id}${name}`;
}

/* Function to return boolean value when supplied value is string
 or return the value as it is if it is boolean
 or return false when any other type is supplied
*/
export const returnBooleanValue = (value: any) => {
  if (typeof value === 'boolean') return value;
  else if (typeof value === 'string') {
    if (value.toLowerCase() === "true") {
      return true;
    } else if (value.toLowerCase() === "false") {
      return false;
    }
  }
  else {
    return false;
  }
}
