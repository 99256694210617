import "./ChatbotContextPicker.scss";
import { useChatbotContext } from "../ChatbotContext";
import {
  useState,
  useRef,
} from 'react';
import {
  KeyboardArrowUp,
  KeyboardArrowDown,
  Typography,
  Button,
} from "@aiops/styleguide";
import ChatbotContextPickerMenu from "./ChatbotContextPickerMenu";

/**
 * Renders the UI that shows the user the current context and allows them to 
 * change it.
 */
const ChatbotContextPicker = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const anchorPt = useRef(null);
  const {
    contexts,
    currentContext,
  } = useChatbotContext();

  const selectContextButton = (context: string) => {
    const isSelectable = contexts.length > 1;
    return (
      <Button
        className={`${isSelectable ? "clickable" : ""} row context-picker-button`}
        onClick={() => {
          if (isSelectable) {
            setAnchorEl(anchorPt.current);
          }
        }}
      >
        <Typography variant="caption1" className="button-context-name">
          {context}
        </Typography>
        {isSelectable &&
          (anchorEl
            ? <KeyboardArrowUp />
            : <KeyboardArrowDown />
          )
        }
      </Button>
    )
  }

  // Only render the context picker menu if a context has been selected.
  // If not, there's a context prompt that appears as a message in the chat.
  return (
    currentContext
      ? <div
        className="col aiops-chatbot-context-picker-wrap"
        ref={anchorPt}
      >
        <div className="row aiops-chatbot-context-picker-bar">
          <Typography
            variant="caption1"
            className="now-querying"
          >
            Now Querying:
          </Typography>
          {selectContextButton(currentContext?.name || "SELECT")}
        </div>
        <ChatbotContextPickerMenu
          onClose={() => setAnchorEl(null)}
          anchorElement={anchorEl}
          width={anchorPt.current?.clientWidth}
        />
      </div >
      : null
  );
};

export default ChatbotContextPicker;
