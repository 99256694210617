import { useGenAISettingsContext } from "@/context";
import UsecaseFormWithCustomFooter, { DropDownType, FormType } from "../UsecaseFormWithCustomFooter";
import { testConnection as testUserConnection } from "../genaiSettingsHelper/genaiSettingsHelper";
import { useEffect, useState } from "react";
import { Button, FormAutocomplete, FormTextfield, useToast } from "@aiops/styleguide";
import { zodResolver } from "@hookform/resolvers/zod";
import { CONNECTION_TYPE_TO_TEST_KEY, DATA_FORM_FIELDS_BY_CONNECTION_TYPE, dataConnectionErrorHandler, dataConnectionForm, dataConnectionFormDefaultValues, formatDataConnectionFormForPost, formatTestPayload, getDataConnectionValues, getDropdownValues } from "../UsecaseFormWithCustomFooter/utils";
import { SubmitHandler, useForm } from "react-hook-form";
import "./DataConnectionForm.scss";
import { TEST_ENABLED_CONNECTION_TYPES } from "./constants";

const DataConnectionFormContent = ({
  control,
  disabled,
  connectionType,
  apiMethodTypes,
  databaseTypes,
  fileStoreTypes,
}: {
  control: any,
  disabled: boolean,
  connectionType: "API" | "DOCUMENTDB" | "SQL" | "FILE",
  apiMethodTypes: DropDownType[],
  databaseTypes: DropDownType[],
  fileStoreTypes: DropDownType[],
}) => {
  // add control_label as key and DropDownType[] as value to pick dropdown values from API
  const DROPDOWN_DATA_BY_KEY = {
    api_method: apiMethodTypes,
    database_type: databaseTypes,
    file_store: fileStoreTypes,
  }
  
  return (
    <div className="row non-dependent-fields" data-testid="level-1-fields">
      {DATA_FORM_FIELDS_BY_CONNECTION_TYPE[connectionType]?.map((field) => {
        if (field.type === "text") {
          return (
            <FormTextfield
              key={field.control_label}
              control={control}
              controlLabel={field.control_label}
              label={field.label}
              placeholder=""
              width={400}
              textfieldProps={{
                disabled: disabled,
                placeholder: field.placeholder,
                margin: "dense",
                type: disabled && field?.hidden ? "password" : "text",
              }}
            />
          );
        } else if (field.type === "autocomplete") {
          return (
            <FormAutocomplete
              key={field.control_label}
              control={control}
              label=""
              displayName={field.label}
              controlLabel={field.control_label}
              data={DROPDOWN_DATA_BY_KEY[field.control_label]}
              width={400}
              multiple={false}
              disabled={disabled}
              disableCloseOnSelect={false}
              textfieldProps={{
                disabled: disabled,
                placeholder: field.placeholder,
                margin: "dense",
              }}
            />
          );
        }
      })}
    </div>
  );
}

const DataConnectionForm = ({ dataConnectionTabData: currentTabData, editable = false, setEditable, onSave, readOnlyDisabled}: FormType) => {
  const { setIsUsecaseDetailsUnsaved, isUsecaseDetailsFormValid, setIsUsecaseDetailsFormValid } = useGenAISettingsContext();
  const [testLoading, setTestLoading] = useState<boolean>(false);
  
  // any dropdowns that may need data from masterData API will need a state here
  const [connectionTypes, setConnectionTypes] = useState<DropDownType[]>([]);
  const [apiMethodTypes, setApiMethodTypes] = useState<DropDownType[]>([]);
  const [databaseTypes, setDatabaseTypes] = useState<DropDownType[]>([]);
  const [fileStoreTypes, setFileStoreTypes] = useState<DropDownType[]>([]);

  const { setCurrentToast } = useToast();
  
  const { control, handleSubmit, formState: { dirtyFields, isDirty, isValid }, getValues, setError, watch, reset, clearErrors } = useForm({
    resolver: zodResolver(dataConnectionForm),
    defaultValues: dataConnectionFormDefaultValues,
    values: getDataConnectionValues(currentTabData[0]),
  });
  // watching connection_type because we want to re-render the form fields on change
  // this function is not assigned to any constant because implementing watch() 
  // acts as a trigger for useEffect to getDropDownValues()
  watch("connection_type");

  useEffect(() => {
    getDropdownValues("connection_type", setConnectionTypes, setCurrentToast);
  }, []);

  useEffect(() => {
    setIsUsecaseDetailsUnsaved(isDirty);
  }, [isDirty]);

  useEffect(() => {
    setIsUsecaseDetailsFormValid({...isUsecaseDetailsFormValid, dataConnectionForm: isValid});
  }, [isValid]);

  useEffect(() => {
    const { connection_type } = getValues();
    if (connection_type?.value === "API") {
      getDropdownValues("API_METHOD", setApiMethodTypes, setCurrentToast);
    }
    if (connection_type?.value === "SQL") {
      getDropdownValues("DATABASE_TYPE", setDatabaseTypes, setCurrentToast);
    }
    if (connection_type?.value === "FILE") {
      getDropdownValues("file_store", setFileStoreTypes, setCurrentToast);
    }
    
  }, [getValues("connection_type.value")])


  const onSubmit: SubmitHandler<any> = (form: any) => {
    // submit the form if there are no errors.
    // if form has errors, they have already been displayed.
    if (!dataConnectionErrorHandler(form, setError)) {
      const data = formatDataConnectionFormForPost(form);
      onSave(data, "data_connection_details");
    }
  }

  const testConnection = async () => {
    const { connection_type } = getValues();
    if (connection_type?.value) {
      const connectionTypeForTest = CONNECTION_TYPE_TO_TEST_KEY[connection_type.value]
      const payload = formatTestPayload(getValues);
      if (!dataConnectionErrorHandler(getValues(), setError)) {
        setTestLoading(true);
        await testUserConnection(connectionTypeForTest, payload).then((res) => {
          setCurrentToast({
            message: res ? "Connection Successful" : "Connection Failed",
            severity: res ? "success" : "error",
            autoHideDuration: 5000,
          })
        }).catch((err) => {
          setCurrentToast({
            message: err?.message || "Something went wrong. Please try again.",
            severity: "error",
            autoHideDuration: 3000,
          })
        }).finally(() => {
          setTestLoading(false);
          clearErrors();
        });
      }
    }
  }

  return (
    <UsecaseFormWithCustomFooter
      editable={editable}
      // passing onSubmit in both onValid and onInvalid since custom error handler
      // is build in to onSubmit
      onSubmit={handleSubmit(onSubmit, onSubmit)}
      saveDisabled={!editable || Object.keys(dirtyFields).length === 0}
      setEditable={setEditable}
      readOnlyDisabled={readOnlyDisabled}
      reset={reset}
    >
      <div className="data-connection-form-wrapper">
        <div className="fields">
          <div className="dependent-field">
            <FormAutocomplete
              control={control}
              label=""
              displayName="Connection Type*"
              controlLabel="connection_type"
              data={connectionTypes || []}
              width={400}
              multiple={false}
              disabled={!editable}
              disableCloseOnSelect={false}
              textfieldProps={{
                disabled: !editable,
                placeholder: "Select",
                margin: "dense",
              }}
            />
          </div>
          {getValues("connection_type")?.value && (
            <DataConnectionFormContent
              connectionType={getValues("connection_type.value")}
              control={control}
              disabled={!editable}
              apiMethodTypes={apiMethodTypes}
              databaseTypes={databaseTypes}
              fileStoreTypes={fileStoreTypes}
            />
          )}
        </div>
        {TEST_ENABLED_CONNECTION_TYPES.includes(getValues("connection_type")?.value) && (
          <div className="test-connection-wrapper">
            <Button
              variant="filled"
              onClick={testConnection}
              disabled={!editable || testLoading}
            >
              Test Connection
            </Button>
          </div>
        )}
      </div>
    </UsecaseFormWithCustomFooter>

  );
};

export default DataConnectionForm;
