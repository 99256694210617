import { Selectability, TableFooterPaginationProps } from "@aiops/styleguide";
import { DeleteUsecaseStatus, UpdateUsecaseStatus, deleteUseCases, getUsecases, patchUsecaseStatus } from "../genaiSettingsHelper/genaiSettingsHelper"
import { USE_CASE_PAGE_SIZE_OPTIONS } from "./config";

export const DEFAULT_FIRST_PAGE = {
  0: null,
}

export const getTableSelectability = ({
  tableData,
  selectedData,
  setSelectedData,
  setError,
}) => {
  const selectability: Selectability = {
    rowIsSelectable: () => true,
    selectAllCheckbox: true,
    selectAllCheckboxDisabled: tableData.length === 0,
    selectAllCheckboxValue: tableData.length === selectedData.length,
    selectAllCheckboxIndeterminate: selectedData.length > 0 && selectedData.length < tableData.length,
    rowIsSelected: (usecase) => selectedData.some(
      e => e.context === usecase.microsolution_id
        && e.subcontext === usecase.usecase_name.props.usecaseName,
    ),
    onSelectRowToggle: (val, usecase) => {      
      setError("");
      if (val) {
        setSelectedData([
          ...selectedData,
          {
            context: usecase.microsolution_id,
            subcontext: usecase.usecase_name.props.usecaseName,
          }
        ])
      } else {
        setSelectedData([...selectedData].filter((curr) =>
          curr.context !== usecase.microsolution_id
          || curr.subcontext !== usecase.usecase_name.props.usecaseName,
        ))
      }
    },
    onSelectAllToggle: (val) => {
      setError("");
      if (val) {
        setSelectedData(tableData.map((e) => ({
          context: e.microsolution_id,
          subcontext: e.usecase_name,
        })))
      } else {
        setSelectedData([]);
      }
    },
  }
  return selectability;
}

export const getTablePagination = (
  currentPage: number,
  rowsPerPage: number,
  availablePages: Record<number, string>,
  setCurrentPage: Function,
  setRowsPerPage: Function,
  totalCount: number,
): TableFooterPaginationProps => {
  return {
    currentPage: currentPage,
    rowsPerPage: rowsPerPage,
    onNextPage: () => {
      if (currentPage <= Object.entries(availablePages).length - 1) {
        setCurrentPage(currentPage + 1);
      }
    },
    onPreviousPage: () => {
      if (currentPage > 0) {
        setCurrentPage(currentPage - 1);
      }
    },
    onSelectRowsPerPage: (count) => {
      setCurrentPage(0)
      setRowsPerPage(count);
    },
    totalResults: totalCount,
    rowsPerPageOptions: USE_CASE_PAGE_SIZE_OPTIONS,
  }
}

export async function getTableData(
  statuses: Array<string>,
  setLoading: (boolean) => void,
  setData: Function,
  setError: Function,
  setTotalCount: Function = () => { },
  setNextToken: Function = () => { },
  nextToken: string = null,
  limit: number = 100,
) {
  setLoading(true);
  getUsecases(statuses, limit, nextToken).then((res) => {
    if (typeof res !== "string") {
      setData(res.usecases);
      setTotalCount(res?.usecase_count);
      setNextToken(res?.next_token);
    } else {
      throw new Error(res);
    }
  }).catch((err) => {
    setError(`Error fetching ${statuses.join(" and ")} use cases.`);
  }).finally(() => {
    setLoading(false);
  })
};

export async function handleTablePatch(
  action: string,
  selectedData: Array<any>,
  setError: Function,
  setSelectedData: Function,
  setCurrentToast: Function,
  refresh: Function,
) {
  if (selectedData.length === 0) {
    setError(`Please select a use case to ${action}.`);
  } else {
    setError("");
    const dataToPatch: UpdateUsecaseStatus[] = selectedData.map((val) => ({
      context: val.context,
      subcontext: val.subcontext,
      usecase_status: action === "activate" ? "Active" : "Inactive",
    }));

    await patchUsecaseStatus(dataToPatch).then((res) => {
      setCurrentToast({
        message: "Success",
        severity: "success",
        autoHideDuration: 3000,
      });
      setSelectedData([]);
    }).catch((err) => {
      console.error(err);
      setCurrentToast({
        message: "Something went wrong",
        severity: "error",
        autoHideDuration: 3000,
      });
    }).finally(() => {
      refresh();
    });
  }
}

export async function handleDeleteUsecases(
  data: DeleteUsecaseStatus[],
  setCurrentToast: Function,
  refresh: Function,
) {
  await deleteUseCases(data).then((res) => {
    const somethingFailed = Object.values(res?.usecase_deletion_status).some((val) => val === false);
    setCurrentToast({
      message: res?.Message,
      severity: somethingFailed ? "error" : "success",
      autoHideDuration: 3000,
    });
  }).catch((err) => {
    console.error(err);
    setCurrentToast({
      message: "Something went wrong",
      severity: "error",
      autoHideDuration: 3000,
    });
  }).finally(() => {
    refresh();
  })
}