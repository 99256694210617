import { utils } from "@aiops/styleguide";
import { endpoints } from "./constants";
import { UpdateUsecaseDetailsType } from "./utils";
import { DropDownType } from "../UsecaseFormWithCustomFooter";
const { appendToBaseUrl, authenticatedFetchRequest } = utils;

export const getFullUrlFromEndpoint = (endpoint: string | string[], usePrefix: boolean = true) => {
  const asArr = Array.isArray(endpoint) ? endpoint : [endpoint];
  return appendToBaseUrl([usePrefix ? endpoints.PREFIX : "", ...asArr]);
}

export type UseCaseType = {
  is_enabled?: boolean;
  usecase_status?: "Active" | "Inactive" | "Draft";
  usecase: string;
};

export type MicrosolutionType = {
  created_at?: string;
  created_by?: string;
  genai_enabled?: boolean;
  microsolution_id?: string;
  microsolution_name?: string;
  updated_at?: string;
  updated_by?: string;
  usecases?: UseCaseType[];
};

export type MicrosolutionResponseType = {
  response: MicrosolutionType[];
};

export type DetailedUsecaseType = {
  microsolution_id: string;
  microsolution_name: string;
  microsolution_description: string;
  usecase_name: string;
  usecase_description: string;
  usecase_status: "Active" | "Inactive" | "Draft";
};

export type UsecaseResponseType = {
  usecases: DetailedUsecaseType[];
  usecase_count?: number;
  next_token?: string;
};

export type UpdateUsecaseStatus = {
  context: string;
  subcontext: string;
  usecase_status?: "Active" | "Inactive";
};

export type DeleteUsecaseStatus = {
  context: string;
  subcontext: string;
}

export type UsecaseOverviewType = {
  microsolution_id: string;
  usecase_description: string;
  usecase_name: string;
  usecase_prompt: string;
  usecase_type: string;
  streaming_enabled: boolean;
}

export type DataConnectionFormType = {
  connection_name?: string;
  tool_name?: string;
  tool_type?: string;
  tool_description?: string;
  connection_type?: string;
  api_endpoint?: string;
  api_method?: string;
  collection_name?: string;
  connection_error_message?: string;
  database_name?: string;
  data_limit?: number;
  n_days_to_fetch_data?: number;
  db_sort_field_name?: string;
  connection_url_secret_name?: string;
  database_type?: string;
  database_host?: string;
  database_user_secret_name?: string;
  database_password_secret_name?: string;
  last_n_days_to_fetch_data?: number;
  file_store?: string;
  bucket_name?: string;
  object_key?: string;
  semantic_store?: string;
  semantic_store_index?: string;
}

export type llmConfigurationFormType = {
  llm_model_provider?: string;
  llm_deployment_platform?: string;
  llm_azure_deployment_name?: string;
  llm_azure_api_version?: string;
  llm_azure_model_version?: string;
  llm_model_name?: string;
  embedding?: string;
  embedding_model?: string;
  embedding_model_azure_deployment?: string;
  default_usecase_sample_query?: {
    title: string;
    description: string;
  }[];
  default_usecase_info_msg?: string;
  semantic_store?: string;
  semantic_store_index?: string;
}

export type UsecaseDetailsType = {
  overview_details?: UsecaseOverviewType;
  data_connection_details?: DataConnectionFormType[];
  llm_configuration_details?: Record<string, any>;
  isNewUsecase: boolean;
}

export const getMicrosolutions = async () => {
  const endpoint = endpoints.MICROSOLUTIONS;
  const url = getFullUrlFromEndpoint(endpoint);

  const rawRes: MicrosolutionType[] = await authenticatedFetchRequest(url, "GET", null)
  
  return rawRes;
}

export const getUsecases = async (status: Array<string> = ["Active", "Inactive", "Draft"], limit: number = 100, nextToken?: string) => {  
  const nextTokenQuery = nextToken ? `&next_token=${nextToken}` : "";
  const endpoint = endpoints.USECASES
    .concat(`?limit=${limit}&`)
    .concat("usecase_status=")
    .concat(status.join(",").concat(`${nextTokenQuery}`));
  const url = getFullUrlFromEndpoint(endpoint);

  const rawRes: UsecaseResponseType = await authenticatedFetchRequest(url, "GET", null)

  return rawRes || "Error fetching table data";
}

export const patchUsecaseStatus = async (usecaseList: UpdateUsecaseStatus[]) => {
  const endpoint = endpoints.PATCH_USECASE_STATUS;
  const url = getFullUrlFromEndpoint(endpoint);

  const rawRes: UsecaseResponseType = await authenticatedFetchRequest(url, "PATCH", usecaseList);
  return rawRes;
}

export const deleteUseCases = async (usecaseList: DeleteUsecaseStatus[]) => {
  const endpoint = endpoints.DELETE_USECASE;
  const url = getFullUrlFromEndpoint(endpoint);
  const payload = {
    usecases: usecaseList,
  }  

  const rawRes = await authenticatedFetchRequest(url, "POST", payload);
  
  return rawRes?.response;
}

export const getUsecaseDetails = async(microsolution: string, usecase: string): Promise<UsecaseDetailsType> => {
  const endpoint = endpoints.USECASE_DETAILS.concat(`?microsolution_id=${microsolution}&usecase_name=${usecase}`);
  const url = getFullUrlFromEndpoint(endpoint);

  const rawRes = await authenticatedFetchRequest(url, "GET");
  if (!rawRes || rawRes?.detail) {    
    throw new Error(rawRes?.detail || "Something went wrong.");
  }
  return rawRes
}

export const postUsecaseDetails = async (payload: UpdateUsecaseDetailsType) => {
  const endpoint = endpoints.REGISTER_USE_CASE;
  const url = getFullUrlFromEndpoint(endpoint);
  const rawRes = await authenticatedFetchRequest(url, "POST", payload).catch((err) => {
    throw (err);
  });
  return rawRes;
}

export const getMasterDataValues = async (masterDataType: string, parentMasterDataValue?: string): Promise<DropDownType[]> => {
  const parentMasterDataValueQuery = parentMasterDataValue ? `&parent_master_data_value=${parentMasterDataValue}` : "";
  const endpoint = endpoints.MASTER_DATA.concat(`?master_data_type=${masterDataType}${parentMasterDataValueQuery}`);
  
  const url = getFullUrlFromEndpoint(endpoint);

  const rawRes = await authenticatedFetchRequest(url, "GET");
  let dropdownValues: DropDownType[] = [];
  if (rawRes?.response) {
    dropdownValues = rawRes?.response?.map((val) => ({
      label: val,
      value: val,
    }))
  } else {
    throw new Error("Error fetching master data values for: " + masterDataType)
  }
  return dropdownValues;
}

export const testConnection = async (connectionType: string, payload: Record<string, string>): Promise<boolean> => {
  const endpoint = endpoints.TEST_CONNECTION.concat(connectionType);
  const url = getFullUrlFromEndpoint(endpoint);

  const rawRes = await authenticatedFetchRequest(url, "POST", payload);
  if (!rawRes) {
    throw new Error("Error testing connection");
  }
  return rawRes?.response;
}
