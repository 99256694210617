import * as z from "zod";
import { DataConnectionFormType, getMasterDataValues } from "../genaiSettingsHelper/genaiSettingsHelper";

export const overviewForm = z.object({
  microsolution_id: z.object({
    label: z.string().nonempty(),
    value: z.string().nonempty(),
  }, {
    required_error: "This field is required",
    invalid_type_error: "This field is required",
  }),
  usecase_name: z.string().trim().nonempty("This field is required").regex(/^[a-zA-Z0-9-_ ]+$/, "Invalid character"), 
  usecase_type: z.object({
    label: z.string().nonempty(),
    value: z.string().nonempty(),
  }, {
    required_error: "This field is required",
    invalid_type_error: "This field is required",
  }),
  usecase_description: z.string().nonempty({ message: "This field is required"}),
  usecase_prompt: z.string().optional(),
  streaming_enabled: z.boolean().optional(),
});

export const dataConnectionForm = z.object({
  connection_type: z.object({
    label: z.string().nonempty(),
    value: z.string().nonempty(),
  }),
  api_endpoint: z.string().optional(),
  api_method: z.object({
    label: z.string().optional(),
    value: z.string().optional(),
  }).optional().nullable(),
  dynamodb_table_name: z.string().optional(),
  aws_region: z.string().optional(),
  dynamodb_filters: z.string().optional(),
  file_store: z.object({
    label: z.string().optional(),
    value: z.string().optional(),
  }).optional().nullable(),
  bucket_name: z.string().optional(),
  object_key: z.string().optional(),
  database_name: z.string().optional(),
  collection_name: z.string().optional(),
  connection_url_secret: z.string().optional(),
  data_limit: z.string().optional(),
  n_days_to_fetch_data: z.string().optional(),
  db_sort_field_name: z.string().optional(),
  database_type: z.object({
    label: z.string().optional(),
    value: z.string().optional(),
  }).optional().nullable(),
  database_host: z.string().optional(),
  database_username_secretname: z.string().optional(),
  database_password_secretname: z.string().optional(),
});

export const dataConnectionFormDefaultValues = {
  connection_type: {
    label: "",
    value: "",
  },
  api_endpoint: "",
  api_method: {
    label: "",
    value: "",
  },
  dynamodb_table_name: "",
  aws_region: "",
  dynamodb_filters: "",
  file_store: {
    label: "",
    value: "",
  },
  bucket_name: "",
  object_key: "",
  database_name: "",
  collection_name: "",
  connection_url_secret: "",
  data_limit: "",
  n_days_to_fetch_data: "",
  db_sort_field_name: "",
  database_type: {
    label: "",
    value: "",
  },
  database_host: "",
  database_username_secretname: "",
  database_password_secretname: "",
};

const getObjValue = ({key, data}) => {
  if(data?.[key]) {
    return {
      label: data[key],
      value: data[key],
    }
  } else {
    return null;
  }
}

export function getDataConnectionValues(currentTabData: DataConnectionFormType) {
  const connectionTypeValue = getObjValue({key: "connection_type", data: currentTabData});
  const apiMethodValue = getObjValue({key: "api_method", data: currentTabData});
  const fileStoreValue = getObjValue({key: "file_store", data: currentTabData});
  const databaseTypeValue = getObjValue({key: "database_type", data: currentTabData});

  return {
    connection_type: connectionTypeValue,
    api_endpoint: currentTabData?.api_endpoint || "",
    api_method: apiMethodValue,
    collection_name: currentTabData?.collection_name || "",
    database_name: currentTabData?.database_name || "",
    data_limit: currentTabData?.data_limit ? String(currentTabData?.data_limit) : "",
    n_days_to_fetch_data: currentTabData?.n_days_to_fetch_data ? String(currentTabData?.n_days_to_fetch_data) : "",
    bucket_name: currentTabData?.bucket_name || "",
    file_store: fileStoreValue,
    object_key: currentTabData?.object_key || "",
    db_sort_field_name: currentTabData?.db_sort_field_name || "",
    connection_url_secret: currentTabData?.connection_url_secret_name || "",
    database_type: databaseTypeValue,
    database_host: currentTabData?.database_host || "",
    database_password_secretname: currentTabData?.database_password_secret_name || "",
    database_username_secretname: currentTabData?.database_user_secret_name || "",
  }
}

export const llmConfigurationForm = z.object({
  semantic_store: z.object({
    label: z.string().optional(),
    value: z.string().optional(),
  }).optional().nullable(),
  semantic_store_index: z.string().optional(),
  llm_model_provider: z.object({
    label: z.string().optional(),
    value: z.string().optional(),
  }).optional().nullable(),
  llm_deployment_platform: z.object({
    label: z.string().optional(),
    value: z.string().optional(),
  }).optional().nullable(),
  llm_azure_deployment_name: z.string().optional(),
  embedding_model_azure_deployment: z.string().optional(),
  llm_azure_model_version: z.string().optional(),
  llm_azure_api_version: z.string().optional(),
  llm_model_name: z.object({
    label: z.string().optional(),
    value: z.string().optional(),
  }).optional().nullable(),
  embedding: z.object({
    label: z.string().optional(),
    value: z.string().optional(),
  }).optional().nullable(),
  embedding_model: z.object({
    label: z.string().optional(),
    value: z.string().optional(),
  }).optional().nullable(),
  default_usecase_info_message: z.string().optional(),
  default_usecase_sample_query: z.array(z.object({
    title: z.string().optional(),
    description: z.string().optional(),
  })).optional(),
})

/**
 * This object is used to render the form fields
 * default connection types are API, DOCUMENTDB, SQL and FILE.
 * 
 * each connection type has an array of fields with the following structure
 * 
 * Common fields:
 * label - string to display above the field
 * control_label - control label used to id the field by useForm hook defined in the zodResolver
 * type - string = "text" | "autocomplete"
 * hidden? - boolean value if the field has sensitive data to be hidden in read-only mode
 * data? - placeholder dropdown values in case API call isn't needed to fetch the data
 * 
 */
export const DATA_FORM_FIELDS_BY_CONNECTION_TYPE = {
  API: [
    {
      label: "API Endpoint*",
      control_label: "api_endpoint",
      type: "text",
      placeholder: "API endpoint",
    },
    {
      label: "API Method*",
      control_label: "api_method",
      type: "autocomplete",
      placeholder: "Select",
      data: [
        {
          label: "GET",
          value: "GET",
        },
        {
          label: "POST",
          value: "POST",
        },
        {
          label: "PUT",
          value: "PUT",
        },
        {
          label: "DELETE",
          value: "DELETE",
        }
      ]
    }
  ],
  DOCUMENTDB: [
    {
      label: "Collection Name*",
      control_label: "collection_name",
      type: "text",
      placeholder: "Collection name",
    },
    {
      label: "Database Name*",
      control_label: "database_name",
      type: "text",
      placeholder: "Database name",
    },
    {
      label: "Data Limit",
      control_label: "data_limit",
      type: "text",
      placeholder: "Data limit",
    },
    {
      label: "Last N Days to Fetch Data",
      control_label: "n_days_to_fetch_data",
      type: "text",
      placeholder: "Last N days to fetch data",
    },
    {
      label: "DB Sort Field Name",
      control_label: "db_sort_field_name",
      type: "text",
      placeholder: "Column name",
    },
    {
      label: "Connection URL Secret Name*",
      control_label: "connection_url_secret",
      type: "text",
      placeholder: "Secret name",
      hidden: true,
    }
  ],
  SQL: [
    {
      label: "Database Type*",
      control_label: "database_type",
      type: "autocomplete",
      placeholder: "Select",
      data: [
        {
          label: "SQL",
          value: "SQL",
        },
      ]
    },
    {
      label: "Database Host*",
      control_label: "database_host",
      type: "text",
      placeholder: "Database host",
    },
    {
      label: "Database Name*",
      control_label: "database_name",
      type: "text",
      placeholder: "Database name",
    },
    {
      label: "Data Limit",
      control_label: "data_limit",
      type: "text",
      placeholder: "Data limit",
    },
    {
      label: "DB User Secret Name*",
      control_label: "database_username_secretname",
      type: "text",
      placeholder: "Secret name",
      hidden: true,
    },
    {
      label: "DB Password Secret Name*",
      control_label: "database_password_secretname",
      type: "text",
      placeholder: "Secret name",
      hidden: true,
    }
  ],
  FILE: [
    {
      label: "File Store*",
      control_label: "file_store",
      type: "autocomplete",
      placeholder: "File store",
      data: [
        {
          label: "S3",
          value: "S3",
        }
      ]
    },
    {
      label: "Bucket Name*",
      control_label: "bucket_name",
      type: "text",
      placeholder: "Bucket name",
    },
    {
      label: "Object Key (File Name)*",
      control_label: "object_key",
      type: "text",
      placeholder: "Object key",
      hidden: true,
    },
  ]
}

export const LLM_FORM_FIELDS = [
  {
    label: "LLM Model Provider*",
    control_label: "llm_model_provider",
    type: "autocomplete",
    placeholder: "LLM model provider",
    data: [],
    isSemanticSearchOnly: false,
  },
  {
    label: "LLM Model Name",
    control_label: "llm_model_name",
    type: "autocomplete",
    placeholder: "LLM model name",
    data: [],
    isSemanticSearchOnly: false,
  },
  {
    label: "Embedding",
    control_label: "embedding",
    type: "autocomplete",
    placeholder: "Embedding",
    data: [],
    isSemanticSearchOnly: false,
  },
  {
    label: "Embedding Model",
    control_label: "embedding_model",
    type: "autocomplete",
    placeholder: "Embedding model",
    data: [],
    isSemanticSearchOnly: false,
  }
];

export const DEPLOYMENT_PLATFORM_FIELDS = [
  {
    label: "LLM Azure Deployment Name*",
    control_label: "llm_azure_deployment_name",
    type: "text",
    placeholder: "LLM Deployment name",
  },
  {
    label: "Embedding Model Azure Deployment",
    control_label: "embedding_model_azure_deployment",
    type: "text",
    placeholder: "Embedding model azure deployment",
  },
  {
    label: "LLM Azure API Version",
    control_label: "llm_azure_api_version",
    type: "text",
    placeholder: "LLM API version",
  },
  {
    label: "LLM Azure Model Version",
    control_label: "llm_azure_model_version",
    type: "text",
    placeholder: "LLM model version",
  },
]

const getDataConnectionErrors = (form: any, setError: Function, requiredFields: string[], numberFields: string[]) => {
  let hasError: boolean = false;
  requiredFields.forEach((field) => {
    if (!form?.[field]) {
      setError(field, {
        type: "required",
        message: "This field is required"
      });
      hasError = true;
    }
  });
  numberFields.forEach((field) => {
    if (form?.[field] && isNaN(Number(form[field]))) {
      setError(field, {
        type: "required",
        message: "Value is not a number"
      });
      hasError = true;
    }
  });
  return hasError;
}
  

/**
 * @param form
 * Current state of form object
 * @param setError
 * Set error function from react-hook-form
 * 
 * This function is a custom validator function for the data connection form
 * 
 * @returns
 * true if form has errors
 * false if form doesn't have errors
 */
export function dataConnectionErrorHandler(form: any, setError: Function): boolean {
  let hasError: boolean = false;
  let requiredFields: string[] = [];
  let numberFields: string[] = [];
  if (form.connection_type?.value === "API") {
    requiredFields = ["api_endpoint", "api_method"];
    hasError = getDataConnectionErrors(form, setError, requiredFields, numberFields);
  } else if (form?.connection_type?.value === "DOCUMENTDB") {
    requiredFields = ["collection_name", "database_name", "connection_url_secret"];
    numberFields = ["data_limit", "n_days_to_fetch_data"];
    hasError = getDataConnectionErrors(form, setError, requiredFields, numberFields);
  } else if (form?.connection_type?.value === "SQL") {
    requiredFields = ["database_type", "database_host", "database_name", "database_username_secretname", "database_password_secretname"];
    numberFields = ["data_limit"];
    hasError = getDataConnectionErrors(form, setError, requiredFields, numberFields);
  } else if (form?.connection_type?.value === "FILE") {
    requiredFields = ["file_store", "bucket_name", "object_key"];
    hasError = getDataConnectionErrors(form, setError, requiredFields, numberFields);
  }
  return hasError;
}

const isFieldValueValid = ({condition, value}) => {
  return condition && !value;
}

export function llmConfigurationErrorHandler(form: any, isSemanticSearchOnly: boolean, setError: Function) {
  let hasError: boolean = false;
  const isAzureLLMDeployment = form?.llm_deployment_platform?.value === "AZURE";
  const isAzureLLMDeploymentNameValid = isFieldValueValid({condition: isAzureLLMDeployment, value: form?.llm_azure_deployment_name});

  if (!form?.llm_model_provider?.value) {
    setError("llm_model_provider", {
      type: "required",
      message: "This field is required"
    });
    hasError = true;
  }
  if (isAzureLLMDeploymentNameValid) {
    setError("llm_azure_deployment_name", {
      type: "required",
      message: "This field is required"
    });
    hasError = true;
  }
  form?.default_usecase_sample_query.forEach((query, index) => {
    if (!(form?.default_usecase_sample_query[0]?.title === "" && form?.default_usecase_sample_query[0]?.description === "")) {
      if (!query.title) {
        setError(`default_usecase_sample_query[${index}].title`, {
          type: "required",
          message: "This field is required"
        });
        hasError = true;
      }
      if (!query.description) {
        setError(`default_usecase_sample_query[${index}].description`, {
          type: "required",
          message: "This field is required"
        });
        hasError = true;
      }
    }
    if (query?.description?.length > 100) {
      setError(`default_usecase_sample_query[${index}].description`, {
        type: "required",
        message: "Description length should be less than 100 characters"
      });
      hasError = true;
    }
  })
  return hasError;
}

// reusable function that gets masterDataValues (dropdown options) and assigns it to the 
// specified setState function
/**
 * 
 * @param masterDataValue required parameter - dropdown control value
 * @param stateSetter setState function to set the state to fetched dropdown data
 * @param setCurrentToast used to show an error message
 * @param parentMasterDataValue optional parameter - parent dropdown control value
 */
export async function getDropdownValues(
  masterDataValue: string,
  stateSetter: Function,
  setCurrentToast: Function,
  parentMasterDataValue: string = "",
) {
  await getMasterDataValues(masterDataValue, parentMasterDataValue).then((res) => {
    stateSetter(res);
  }).catch((err) => {
    setCurrentToast({
      message: err.message,
      severity: "error",
      autoHideDuration: 10000,
    })
  })
};

export function formatDataConnectionFormForPost(form: Record<string, any>): Array<Record<string, any>> {
  const payload: Array<Record<string, any>> = [{
    tool_name: "",
    tool_description: "",
    tool_type: "",
    connection_name: "",
    connection_type: form.connection_type?.value,
    api_endpoint: form?.api_endpoint,
    api_method: form?.api_method?.value,
    collection_name: form?.collection_name,
    database_name: form?.database_name,
    data_limit: form?.data_limit || null,
    n_days_to_fetch_data: form?.n_days_to_fetch_data || null,
    db_sort_field_name: form?.db_sort_field_name,
    connection_url_secret_name: form?.connection_url_secret,
    database_type: form?.database_type?.value,
    database_host: form?.database_host,
    database_user_secret_name: form?.database_username_secretname,
    database_password_secret_name: form?.database_password_secretname,
    file_store: form?.file_store?.value,
    bucket_name: form?.bucket_name,
    object_key: form?.object_key,
    semantic_store: form?.semantic_store?.value,
    semantic_store_index: form?.semantic_store_index,
  }];

  return payload;
}

export function formatLlmConfigurationFormForPost(form: Record<string, any>): Record<string, any> {
  const payload: Record<string, any> = {};
  payload["default_usecase_sample_query"] = form?.default_usecase_sample_query?.filter((query) => query.title !== "" && query.description !== "");
  
  payload["semantic_store"] = form?.semantic_store?.value;
  payload["semantic_store_index"] = form?.semantic_store_index || null;
  payload["llm_model_provider"] = form?.llm_model_provider?.value;
  payload["llm_model_name"] = form?.llm_model_name?.value;
  payload["embedding_platform"] = form?.embedding?.value;
  payload["embedding_model"] = form?.embedding_model?.value;
  payload["llm_deployment_platform"] = form?.llm_deployment_platform?.value;
  payload["llm_azure_deployment_name"] = form?.llm_azure_deployment_name || null;
  payload["embedding_model_azure_deployment"] = form?.embedding_model_azure_deployment || null;
  payload["llm_azure_api_version"] = form?.llm_azure_api_version || null;
  payload["llm_azure_model_version"] = form?.llm_azure_model_version || null;
  payload["default_usecase_info_message"] = form?.default_usecase_info_message || null;
  return payload;
}

export const CONNECTION_TYPE_TO_TEST_KEY = {
  DOCUMENTDB: "database",
  SQL: "sql",
  FILE: "file",
}

export function formatTestPayload(getValues: Function) {
  const {
    database_type,
    database_host,
    database_name,
    database_username_secretname,
    database_password_secretname,
    connection_url_secret,
    collection_name,
    file_store,
    bucket_name,
    object_key,
  } = getValues();
  const payload: Record<string, string> = {};
  payload["database_type"] = database_type?.value || null;
  payload["database_host"] = database_host || null;
  payload["database_name"] = database_name || null;
  payload["database_username_secretname"] = database_username_secretname || null;
  payload["database_password_secretname"] = database_password_secretname || null;
  payload["connection_url_secret_name"] = connection_url_secret || null;
  payload["collection_name"] = collection_name || null;
  payload["file_store"] = file_store?.value || null;
  payload["bucket_name"] = bucket_name || null;
  payload["object_key"] = object_key || null;

  return payload;
}
