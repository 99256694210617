import React, { useEffect, useState } from "react";
import "./ConnectionField.scss";
import { Typography, FormAutocomplete, FormTextfield, Button, IconButton } from "@aiops/styleguide";
import { DropDownType } from "../../UsecaseFormWithCustomFooter";
import connectionExpand from "../../../assets/icons/connectionExpand.svg";
import connectionContract from "../../../assets/icons/connectionContract.svg";
import deleteConnectionIcon from "../../../assets/icons/deleteConnection.svg";
import { DataConnectionFormType, testConnection as testUserConnection } from "../../genaiSettingsHelper/genaiSettingsHelper";
import { GENERIC_DATA_CONNECTION_FIELDS, TEST_ENABLED_CONNECTION_TYPES } from "../utils";
import { formatGenericTestPayload, formatUsecaseType, hasConnectionErrors } from "./utils";
import { CONNECTION_TYPE_TO_TEST_KEY } from "../../UsecaseFormWithCustomFooter/utils";

const ConnectionField = ({
  tabData,
  control,
  index,
  editable,
  apiMethodTypes,
  databaseTypes,
  connectionTypes,
  fileStoreTypes,
  semanticStoreOptions,
  isSemanticStore,
  remove,
  setToast,
  disableDelete,
  getValues,
  watch,
  errors,
  clearErrors,
  setErrors,
}: {
  tabData: DataConnectionFormType;
  control: any;
  index: number;
  editable: boolean;
  apiMethodTypes: DropDownType[];
  databaseTypes: DropDownType[];
  connectionTypes: DropDownType[];
  fileStoreTypes: DropDownType[];
  semanticStoreOptions: DropDownType[];
  isSemanticStore: boolean;
  remove: (index: number) => void;
  setToast: Function,
  disableDelete: boolean,
  getValues: Function,
  watch: Function,
  errors: Record<string, any>,
  setErrors: Function,
  clearErrors: Function,
}) => {
  const [expanded, setExpanded] = useState<boolean>(
    !tabData?.connection_type || !tabData?.tool_name || isSemanticStore
  );
  const [testLoading, setTestLoading] = useState<boolean>(false);
  const formValues = getValues(`formValues[${index}]`);

  const CONNECTION_TYPE_TO_DROPDOWN = {
    API: apiMethodTypes,
    DOCUMENTDB: databaseTypes,
    SQL: databaseTypes,
    FILE: fileStoreTypes,
    SEMANTIC_SEARCH: semanticStoreOptions,
  }
  watch(`formValues[${index}].connection_type`);

  useEffect(() => {
    errors?.formValues?.forEach((_, value) => {
      if (value === index) {
        setExpanded(true);
      };
    })

  }, [Object.keys(errors).length]);

  const testConnection = async () => {
    const { connection_type } = formValues;
    if (connection_type?.value) {
      const connectionTypeForTest = CONNECTION_TYPE_TO_TEST_KEY[connection_type.value]
      const payload = formatGenericTestPayload(formValues);
      if (!hasConnectionErrors(formValues, index, setErrors)) {
        setTestLoading(true);
        await testUserConnection(connectionTypeForTest, payload).then((res) => {
          setToast({
            message: res ? "Connection Successful" : "Connection Failed",
            severity: res ? "success" : "error",
            autoHideDuration: 5000,
          })
        }).catch((err) => {
          setToast({
            message: err?.message || "Something went wrong. Please try again.",
            severity: "error",
            autoHideDuration: 3000,
          })
        }).finally(() => {
          setTestLoading(false);
          clearErrors();
        });
        setTestLoading(false);
      }
    }
  }

  return (
    <div>
      <div className="connection-field-wrapper">
        <div className="connection-title-row">
          <div className="title">
            <IconButton
              onClick={() => setExpanded(!expanded)}
              className={expanded ? "clickable expanded" : "clickable"}
            >
              <img
                src={expanded ? connectionContract : connectionExpand}
                alt="connection-expand"
              />
            </IconButton>
            {expanded ? (
              <div className="connection-details">
                <FormAutocomplete
                  control={control}
                  data={connectionTypes}
                  controlLabel={`formValues[${index}].connection_type`}
                  label=""
                  width={400}
                  displayName="Connection Type*"
                  disableCloseOnSelect
                  disabled={!editable}
                  multiple={false}
                  textfieldProps={{
                    placeholder: "Select",
                    margin: "dense",
                    disabled: !editable,
                  }}
                />
                <FormTextfield
                  control={control}
                  controlLabel={`formValues[${index}].tool_name`}
                  placeholder=""
                  label="Connection Name*"
                  width={400}
                  textfieldProps={{
                    margin: "dense",
                    placeholder: "Create a unique name",
                    disabled: !editable,
                  }}
                />
              </div>
            ) : (
              <div className="flex row">
                <Typography variant="form-field-label" className="connection-field-title">
                  {formatUsecaseType(formValues?.connection_type?.value) || "New Connection"}
                </Typography>
                {formValues?.tool_name && (
                  <div className="tool-name">
                    <Typography variant="caption1">
                      {formValues?.tool_name}
                    </Typography>
                  </div>
                )}
              </div>
            )}
          </div>
          {!disableDelete && (
            <IconButton
              onClick={() => {
                if (editable) {
                  remove(index)
                }
              }}
            >
              <img
                className={`${editable ? "clickable" : "disabled"}`}
                src={deleteConnectionIcon}
                alt="trash-icon"
              />
            </IconButton>
          )}
        </div>
      </div>
      {expanded && formValues?.connection_type?.value && (
        <div className="fields-wrapper">
          <div className="other-related-fields flex row" data-testid="related-fields">
            {GENERIC_DATA_CONNECTION_FIELDS[formValues?.connection_type?.value]?.map((field) => {
              if (field.type === "text") {
                return (
                  <FormTextfield
                    key={field.control_label}
                    control={control}
                    controlLabel={`formValues[${index}].${field.control_label}`}
                    placeholder=""
                    label={field.label}
                    width={400}
                    textfieldProps={{
                      margin: "dense",
                      placeholder: field.label,
                      disabled: !editable,
                      type: !editable && field?.secret ? "password" : "text",
                    }}
                  />
                );
              } else if (field.type === "dropdown") {
                return (
                  <FormAutocomplete
                    key={field.control_label}
                    control={control}
                    controlLabel={`formValues[${index}].${field.control_label}`}
                    data={CONNECTION_TYPE_TO_DROPDOWN[field.data_key] || []}
                    label=""
                    displayName={field.label}
                    width={400}
                    disabled={!editable}
                    multiple={false}
                    textfieldProps={{
                      margin: "dense",
                      placeholder: field.label,
                      disabled: !editable,
                    }}
                  />
                );
              }
            })}
          </div>
          {TEST_ENABLED_CONNECTION_TYPES.includes(formValues?.connection_type?.value) && (
            <div className="btn-wrapper">
              <Button
                variant="filled"
                className="test-connection"
                disabled={testLoading}
                onClick={testConnection}
              >
                Test Connection
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default ConnectionField;
