import * as React from "react";
import "./UsecaseDetails.scss";
import { Typography, useToast, CircularProgress } from "@aiops/styleguide";
import { useNavigate, useParams } from "react-router-dom";
import { DataConnectionFormType, UsecaseDetailsType, UsecaseOverviewType, getUsecaseDetails, postUsecaseDetails } from "../genaiSettingsHelper/genaiSettingsHelper";
import { formatUsecaseDataToApiPayload, checkRequiredFields, UsecaseTabType, StepperStepType} from "../genaiSettingsHelper/utils";
import UsecaseConfirmationModal from "../UsecaseConfirmationModal";
import { useGenAISettingsContext, UsecaseConfirmationModalObjectType, UsecaseConfirmationModalButtonType} from "@/context/GenAISettingsContext";
import UsecaseDetailsTabs from "./UsecaseDetailsTabs";
import UsecaseDetailsStepper from "./UsecaseDetailsStepper";
import TabToComponent from "../TabToComponent";

const INDEX_TO_KEY = {
  0: "overview_details",
  1: "data_connection_details",
  2: "llm_configuration_details",
}

const STEPPER_STEPS = [
  {
    id: 0,
    label: "Begin Registration",
    form: "overview_details" as UsecaseTabType,
  },
  {
    id: 1,
    label: "Data Connection",
    form: "data_connection_details" as UsecaseTabType,
  },
  {
    id: 2,
    label: "LLM Configuration",
    isComplete: "llm_configuration_details" as UsecaseTabType,
  },
] as StepperStepType[];

// Minimum required fields for each form tab
export const requiredFields = {
  overview_details: ["microsolution_id", "usecase_name", "usecase_type", "usecase_description"],
  data_connection_details: ["connection_type"],
  llm_configuration_details: ["llm_model_provider"],
};

const UsecaseDetails = () => {
  const navigate = useNavigate();
  const { microsolution, usecase } = useParams();
  const { 
    isUsecaseDetailsUnsaved, 
    setIsUsecaseDetailsUnsaved, 
    isUsecaseConfirmationModalOpen, 
    setIsUsecaseConfirmationModalOpen,
    setIsUsecaseDetailsFormValid,
    isUsecaseDetailsFormValid,
    isNewUsecase,
    setIsNewUsecase,
    setUsecaseConfirmationModalButtonClicked
  } = useGenAISettingsContext();
  const [usecaseDetails, setUsecaseDetails] = React.useState<UsecaseDetailsType>(null);
  const [editable, setEditable] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const { setCurrentToast } = useToast();
  const steps = ["Overview Details", "Data Connection", "LLM Configuration"];

  const [activeStep, setActiveStep] = React.useState(0);
  const [newStepValue, setNewStepValue] = React.useState(0);

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    if (usecase) {
      setNewStepValue(newValue);
      if(isUsecaseDetailsUnsaved) {
        setIsUsecaseConfirmationModalOpen({isOpen: true, triggerPoint: 'form-tab-switch'} as UsecaseConfirmationModalObjectType);
      } else {
        setActiveStep(newValue);
      }
    }
  }

  const handleUpdateUseCase = async (data: UsecaseOverviewType | DataConnectionFormType[] | Record<string, any>, tab: UsecaseTabType) => {
    setLoading(true);
    let finalFormState: UsecaseDetailsType = JSON.parse(JSON.stringify(usecaseDetails));
    finalFormState.isNewUsecase = isNewUsecase;
    if (tab === "overview_details") {
      // Handle the scenario if user hits the URL directly to create new usecase
      if (!microsolution || !usecase) {
        finalFormState.isNewUsecase = true;
      }
      finalFormState.overview_details = data as UsecaseOverviewType;
    } else if (tab === "data_connection_details") {
      if (!microsolution || !usecase) {
        setCurrentToast({
          message: "Please add the overview details before updating this tab.",
          severity: "error",
          autoHideDuration: 3000,
        });
        setActiveStep(0);
      } else {
        finalFormState.data_connection_details = data as DataConnectionFormType[];
      }
    } else if (tab === "llm_configuration_details") {
      finalFormState.llm_configuration_details = data;
    }
    const payload = formatUsecaseDataToApiPayload(finalFormState);
    await postUsecaseDetails(payload).then((res) => {
      setCurrentToast({
        message: res?.response,
        severity: "success",
        autoHideDuration: 3000,
      });
      setUsecaseDetails(finalFormState);
      setIsUsecaseDetailsUnsaved(false);
      setIsNewUsecase(false);
      navigate(`/usecase/${finalFormState.overview_details.microsolution_id}/${finalFormState.overview_details.usecase_name}`, {
        replace: true,
      });
      (activeStep === steps.length - 1) && setEditable(false);
    }).catch((err) => {
      setCurrentToast({
        message: err?.message || "Something went wrong. Please try again.",
        severity: "error",
        autoHideDuration: 3000,
      })
    }).finally(() => {
      getUsecaseInfo(finalFormState.overview_details.microsolution_id, finalFormState.overview_details.usecase_name)
      setLoading(false);
      setEditable(false);
    });
  }

  async function getUsecaseInfo(microsolution: string = "", usecase: string = "") {
    if (microsolution && usecase) {
      setLoading(true);
      getUsecaseDetails(microsolution, usecase).then((res) => {
        setUsecaseDetails(res);
      }).catch((err) => {
        setCurrentToast({
          message: err?.message,
          severity: "error",
          autoHideDuration: 3000,
        });
        navigate("/", { replace: true });
      }).finally(() => {
        setLoading(false);
      });
    } else if (!usecase) {
      setUsecaseDetails({
      overview_details: {
        microsolution_id: microsolution || "",
        usecase_name: "",
        usecase_type: "",
        usecase_description: "",
        usecase_prompt: "",
        streaming_enabled: true,
      },
      data_connection_details: [],
      llm_configuration_details: {
        default_usecase_sample_query: [],
      },
      isNewUsecase: isNewUsecase,
    });
      setEditable(true);
    }
  }

  /**
  * Method to check the form validity to update step completion for the stepper
  * If the form has no data, the form object has only null values, set the form validity to false
  * If the form has some fields data saved, which means those are required fields, set the form validity to true
  * @param activeStep
  * The active step in the form
  * Acceptable Values: 0, 1 or 2 denoting below:
  * 0: Overview Details; 1: Data Connection; 2: LLM Configuration
  * @param isObjValid
  * Boolean value to check if minimum required fields are present in the form object
  */
  const checkFormValidity = (activeStep: number, isObjValid: boolean) => {
    INDEX_TO_KEY[activeStep] === 'overview_details' && 
      setIsUsecaseDetailsFormValid({...isUsecaseDetailsFormValid, overviewDetailsForm: isObjValid});
    INDEX_TO_KEY[activeStep] === 'data_connection_details' && 
      setIsUsecaseDetailsFormValid({...isUsecaseDetailsFormValid, dataConnectionForm: isObjValid});
    INDEX_TO_KEY[activeStep] === 'llm_configuration_details' && 
      setIsUsecaseDetailsFormValid({...isUsecaseDetailsFormValid, llmConfigurationForm: isObjValid});
  }

  /**
   * Method called when the modal confirmation (Yes) button is clicked
   * If the modal was triggered by clicking the back button, navigate to the settings page
   * If the modal was triggered by switching tabs in the form, check for minimum required values in the form object and set the form validity state. Set the active step to the new step value
   * If the modal was triggered by clicking the cancel button, set the editable state to false
   */
  const modalConfirmHandler = () => {
    setUsecaseConfirmationModalButtonClicked("yes" as UsecaseConfirmationModalButtonType);
    if(isUsecaseConfirmationModalOpen.triggerPoint === 'back-btn-click') {
      navigate("/");
    } else if(isUsecaseConfirmationModalOpen.triggerPoint === 'form-tab-switch') {
      const isObjValid = checkRequiredFields(usecaseDetails[INDEX_TO_KEY[activeStep]], requiredFields[INDEX_TO_KEY[activeStep]]);
      checkFormValidity(activeStep, isObjValid);
      setActiveStep(newStepValue);
    } else if(isUsecaseConfirmationModalOpen.triggerPoint === 'cancel-btn-click') {
      setEditable(!editable);
    }
    setIsUsecaseDetailsUnsaved(false);
    setIsUsecaseConfirmationModalOpen({isOpen: false, triggerPoint: ''} as UsecaseConfirmationModalObjectType);
  }

  React.useEffect(() => {
    getUsecaseInfo(microsolution, usecase);
  }, []);

  if (loading) {
    return (
      <div className="flex row col">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="usecase-reg-wrapper flex col">
      <Typography className="usecase-header" variant="heading3">{usecase || "Register Use Case"}</Typography>
      {editable && <UsecaseDetailsStepper activeStep={activeStep} steps={STEPPER_STEPS} />}
      <UsecaseDetailsTabs activeStep={activeStep} steps={steps} handleTabChange={handleTabChange} />
      {usecaseDetails && (
        <div className="usecase-detail-form">
          <TabToComponent
            tabName={steps[activeStep]}
            tabData={usecaseDetails[INDEX_TO_KEY[activeStep]]}
            editable={editable}
            onSave={handleUpdateUseCase}
            disableChangeContext={Boolean(microsolution && usecase)}
            setEditable={setEditable}
            readOnlyDisabled={!Boolean(microsolution && usecase)}
            isSemanticStore={Boolean(usecaseDetails?.overview_details?.usecase_type === "SEMANTIC_SEARCH")}
            isGenericFunctions={Boolean(usecaseDetails?.overview_details?.usecase_type === "GENERIC_FUNCTIONS")}
            usecaseType={usecaseDetails?.overview_details?.usecase_type}
          />
        </div>
      )}
      {isUsecaseConfirmationModalOpen.isOpen && 
        <UsecaseConfirmationModal 
          open={isUsecaseConfirmationModalOpen.isOpen}
          onCancel={() => {
            setUsecaseConfirmationModalButtonClicked("no" as UsecaseConfirmationModalButtonType);
            setIsUsecaseConfirmationModalOpen({isOpen: false, triggerPoint: ''} as UsecaseConfirmationModalObjectType);
          }}
          onClose={() => {
            setUsecaseConfirmationModalButtonClicked("no" as UsecaseConfirmationModalButtonType);
            setIsUsecaseConfirmationModalOpen({isOpen: false, triggerPoint: ''} as UsecaseConfirmationModalObjectType);
          }}
          onConfirm={modalConfirmHandler}
          title="Unsaved Changes"
          message="You have unsaved changes which will be lost and cannot be undone. Are you sure you want to leave?"
          cancelButtonText="No"
          confirmationButtonText="Yes"
        />
      }
    </div>
  );
};

export default UsecaseDetails;
