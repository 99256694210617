import { useState } from 'react';
import "./SuggestedQueryContainer.scss"
import { useChatbotContext } from '../ChatbotContext';
import SuggestedQuery from '../SuggestedQuery/SuggestedQuery';
import { Collapse, Expand, OpenInFullIcon, Button } from '@aiops/styleguide';

type SuggestedQueryContainerProps = {
  onSubmit: (query: string) => void;
  disabled: boolean;
}

/**
 * 
 * @param onSubmit
 * Function to be called when an item is clicked
 * @param disabled
 * boolean indicating whether the list is disabled
 * @returns react component
 */
const SuggestedQueryContainer = ({ onSubmit, disabled }: SuggestedQueryContainerProps) => {
  const { suggestedQueries, expanded, setExpanded } = useChatbotContext();
  const [enlarged, setEnlarged] = useState<boolean>(true);

  if (!suggestedQueries || suggestedQueries.length === 0) {
    return <></>
  }

  const handleClickExpand = () => {
    if (!enlarged) {
      setEnlarged(true);
      return;
    }
    setExpanded(true);
  }

  function getBoxClass() {
    // min-height of 60px either if chatbot width is expanded or queries are not shown
    // min-height of 430px if chatbot width is not expanded and queries are shown
    if (expanded || !enlarged) {
      return "small"
    } else if (!expanded && enlarged) {
      return "large"
    }
  }

  return (
    <div className={`suggested-query-parent ${getBoxClass()}`}>
      <Button className="retract-button clickable" onClick={() => setEnlarged(!enlarged)} disableRipple>
        {enlarged ? (
          <>
            Hide Suggestions <Collapse />
          </>
        ) : (
          <>
            Show Suggestions <Expand />
          </>
        )}
      </Button>
      {enlarged && (
        <div className="queries-box">
          <div className={`${expanded ? "large" : "small"}`}>
            {suggestedQueries.slice(0, expanded ? suggestedQueries.length : 4).map(q => {
              return (
                <SuggestedQuery
                  key={q.title}
                  title={q.title}
                  description={q.description}
                  onClick={onSubmit}
                  disabled={disabled}
                />
              )
            })}
          </div>
        </div>
      )}
      {(!expanded && enlarged) && (
        <Button className="show-more clickable" onClick={handleClickExpand} disableRipple>
          <div className="expand-icon">
            <OpenInFullIcon />
          </div>
          Explore more suggestions...
        </Button>
      )}
    </div>
  );
};

export default SuggestedQueryContainer;
