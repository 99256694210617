import "./SuggestedQuery.scss";
import { Button } from "@aiops/styleguide";

type SuggestedQueryType = {
  title: any;
  description: any;
  onClick: (query: string, preventDefault: boolean, customQuery: boolean) => void;
  disabled: boolean;
}

/**
 * 
 * @param title
 * String title which displays the title in a pill
 * @param description
 * String description which displays the description
 * @param onClick
 * Function to call returns the description
 * @param disabled
 * boolean value indicating whether the suggestion is disabled
 *
 * @returns React component
 */
const SuggestedQuery = ({ title, description, onClick, disabled }: SuggestedQueryType) => {
  
  return (
    <Button className={`query-wrapper ${disabled ? "disabled": ""}`} onClick={() => {
      if (!disabled) {
        onClick(description, true, true)
      }}}
      disableRipple
    >
      <div className="query-title">{title}</div>
      <div className="query-description">{description}</div>
    </Button>
  )
}

export default SuggestedQuery;
