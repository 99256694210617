import { utils } from "@aiops/styleguide";
import { endpoints } from './constants';
import { getConfig } from '@aiops/root-config';
class FatalError extends Error {}

const {
    appendToBaseUrl,
    authenticatedFetchRequest,
    authenticatedFetchEventSourceRequest,
} = utils;

export const formatStreamResponse = (data: string) => {
    if (data === "") {
       return "\n";
    } else {
        return data;
    }
  };

/**
 * Returns the full url for the given endpoint, including a prefix if the
 * usePrefix argument is true (it is true by default).
 * 
 * @param endpoint 
 * The endpoint to append to the base url.
 * 
 * @param usePrefix 
 * When true, the app's prefix will be added in between the base url and the
 * 
 */
export const getFullUrlFromEndpoint = (endpoint: string | string[], usePrefix: boolean = true) => {
    const asArr = Array.isArray(endpoint) ? endpoint : [endpoint];
    return appendToBaseUrl([usePrefix ? endpoints.PREFIX : "", ...asArr]);
}

export type GenAIContextType = {
    name: string;
    streaming_enabled: boolean;
}

/**
 * Returns a list of contexts for the given appId. If no appId is provided, or
 * there are no contexts for the given appId, or there is no id token available
 * for the user from the auth-util app, returns an empty array.
 * 
 * @param appId 
 * The id of the app for which to retrieve contexts.
 * 
 * @returns 
 * An array of strings, which can be empty.
 */
export const getChatbotContexts = async (appId: string): Promise<GenAIContextType[]> => {
    if (!appId) {
        return [];
    }

    const url = getFullUrlFromEndpoint(endpoints.CONTEXTS);
    const headers = {
        ApplicationId: appId,
    }
    const rawRes = await authenticatedFetchRequest(
        `${url}?microsolution_id=${appId}`,
        "GET",
        null,
        headers
    );
    const contexts = rawRes?.response?.usecases || null;

    if (!contexts) {
        return [];
    } else if (!Array.isArray(contexts)) {
        // If something other than null or an array was returned, log an error
        // and return an empty array so that no gen ai chatbot is rendered.
        console.error(`Expected array of contexts but got: ${contexts}`);
        return [];
    }
    return contexts;
}

// Add Context -> URL mappings here.
const CONTEXT_URL_MAPPER = {
    "Guided Buying and Policies": endpoints.LEX_QUERY,
}

export const getChatbotResponse = async (
    appId: string,
    sessionId: string,
    context: string,
    query: string,
): Promise<any> => {
    // If the context has a specific endpoint, it will be picked from the 
    // mapper, else it will default to the genai query endpoint.
    const endpointUrl = Object.keys(CONTEXT_URL_MAPPER)
        .includes(context) ? CONTEXT_URL_MAPPER[context] : endpoints.QUERY;
    
    const url = getFullUrlFromEndpoint(endpointUrl);
    const body = {
        query,
        context: appId,
        subcontext: context,
        session_id: sessionId,
    }
    const headers = {
        ApplicationId: appId,
    }
    const rawRes = await authenticatedFetchRequest(url, "POST", body, headers);
    return rawRes || "Error: unable to get chatbot response";
}

export const getVoiceTranscription = async (
    appId: string,
    sessionId: string,
    context: string,
    audioBlob: string,
): Promise<any> => {
    const endpoint = endpoints.TRANSCRIBE;
    const url = getFullUrlFromEndpoint(endpoint);
    const headers = {
        ApplicationId: appId,
    }    
    const body = {
        subcontext: context,
        audio: audioBlob,
        session_id: sessionId,
    };

    const rawRes = await authenticatedFetchRequest(url, "POST", body, headers);
    return rawRes || "Error: unable to get voice transcription";
}

/**
 * Fetches a streaming response from Chatbot API
 * Streaming response is displayed to the user as and when received from the event stream
 * When the response is received in its entirety, it is appended to the chat history
 * 
 * @param appId
 * Application ID
 * @param sessionId
 * Session ID
 * @param context 
 * Context object with name and streaming_enabled properties
 * @param query
 * User query
 * @param setStreamResponse
 * Function to set the streaming response
 * @param addToChatHistory
 * Function to add the streaming response to chat history
 */
export const getChatbotStreamResponse = async (
    appId: string,
    sessionId: string,
    context: GenAIContextType,
    query: string,
    setStreamResponse: (data: string) => void,
    addToChatHistory: (data: {sender: string, message: string}) => void,
): Promise<any> => {
    const baseUrl = getConfig()?.eventStreamBaseUrl;
    const streamEndpoint = baseUrl + endpoints.STREAMING_QUERY;
    const method = "POST";
    const body = {
        session_id: sessionId,
        query,
        context: appId,
        subcontext: context.name,
    }
    const headers = {
        ApplicationId: appId,
    }
    const ctrl = new AbortController();
    const signal = ctrl.signal;
    let parsedData = "";
    let streamedResponse = "";

    const onopen = async (response: any) => {
        if (response.ok && response.status === 200) {
            console.log("Connection opened");
        } else {
            console.error("Error getting chatbot response", response);
            throw new Error();
        }
    }

    const onmessage = (message: any) => {
        if (message.event === 'FatalError') {
            throw new FatalError(message.data);
        }
        parsedData = formatStreamResponse(message.data);
        streamedResponse += parsedData;
        setStreamResponse(streamedResponse); 
    }

    const onclose = () => {
        console.log("Connection closed");
        if (streamedResponse.length > 0) {
            addToChatHistory({
                sender: 'BOT',
                message: streamedResponse,
            });
            setStreamResponse("");
        }
    }

    const onerror = (error: any) => {
        console.error("Error getting chatbot response", error);
        if (error instanceof Error || error instanceof FatalError) {
            throw error; // rethrow to stop the operation
        }
    }

    await authenticatedFetchEventSourceRequest({
        url: streamEndpoint,
        method, 
        body, 
        headers,
        onopen,
        onmessage,
        onclose,
        onerror,
        signal,
    });
}

/**
 * Returns a new, randomly-generated id for a chat as a string.
 * 
 * @param appId 
 * The appId of the app for which to generate a chat id.
 * 
 * @returns 
 * A string of the form `appId=${appId}&chatId=${randomId}`
 */
export const getNewChatId = (appId: string): string => {
    return `appId=${appId}&chatId=${crypto.randomUUID()}`
}

type UsecaseQuery = {
    title: string;
    description: string;
}

type ContextMessageAndQueryType = {
    usecaseMessage: string;
    usecaseQueries: UsecaseQuery[];
}

/**
 * 
 * @param appId string appId for which the query is called
 * @param context string context for which the query is called
 * @returns object containing default usecase message and list of suggested queries
 */
export const getContextMessageAndQueries = async (appId: string, context: string): Promise<ContextMessageAndQueryType> => {
    const endpoint = `${endpoints.DEFAULT_CONTEXT_MESSAGE}?context=${appId}&subcontext=${context}`
    const headers = {
        ApplicationId: appId,
    }
    const url = getFullUrlFromEndpoint(endpoint)
    const rawRes = await authenticatedFetchRequest(url, "GET", null, headers)
    return {
        usecaseMessage: rawRes?.default_usecase_message || "Error: unable to get context description",
        usecaseQueries: rawRes?.usecase_queries
    }    
}
